.carousel-wrapper {
    position: relative;
}

.carousel {
    display: flex;
    width: 100%;
    overflow-y: hidden;
    padding-bottom: 10px;
    scroll-behavior: smooth;
}

.right-fade {
    position: absolute;
    background: linear-gradient(270.25deg, rgba(255, 255, 255, 0.97) 9.28%, rgba(255, 255, 255, 0) 77.07%);;
    right: 0;
    width: 50px;
    height: 545px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.left-fade {
    position: absolute;
    background: linear-gradient(270.25deg, rgba(255, 255, 255, 0.97) 9.28%, rgba(255, 255, 255, 0) 77.07%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    left: 0;
    width: 50px;
    height: 545px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.carousel-forward {
    cursor: pointer;
    margin-left: 25px;
}

.carousel::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

@media (max-width: 768px) {
    .right-fade {
        position: absolute;
        background: linear-gradient(270.25deg, rgba(255, 255, 255, 0.97) 9.28%, rgba(255, 255, 255, 0) 77.07%);;
        /* right: 0; */
        width: 50px;
        height: 328px;
        align-items: center;
        justify-content: center;
        /* display: none; */
        right: -2px;
    }
    
    .left-fade {
        position: absolute;
        background: linear-gradient(270.25deg, rgba(255, 255, 255, 0.97) 9.28%, rgba(255, 255, 255, 0) 77.07%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        left: -2px;
        width: 50px;
        height: 328px;
        align-items: center;
        justify-content: center;
        /* display: none; */
    }

    .carousel-forward {
        cursor: pointer;
        margin-left: 25px;
        /* display: none; */
    }
}
