.phone-button {
    border-width: 1px 1px 4px 1px;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    border-style: solid;
    box-sizing: border-box;
    width: 163px;
    justify-content: space-between;
    align-items: center;
    border-color: var(--grey-border);
    font-size: 18px;
    cursor: pointer;
    white-space: nowrap;
}

.phone-icon {
    margin-top: 2px;
}
