.news-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--main-font);
}

.news-header {
    padding: 4rem 4rem;
    width: calc(100% - 8rem);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100rem;
}

/* .news-left-header {
    width: 64%;
} */

.news-h1 {
    font-family: var(--main-font);
    font-weight: 500;
    font-size: 50px;
}

.news-button-container {
    padding-top: 20px;
    display: flex;
    gap: 50px;
}

.news-button {
    padding-top: 9px;
}

.news-article-list {
    padding: 0rem 4rem 2rem 4rem;
    width: calc(100% - 8rem);
    max-width: 100rem;
}

.news-article-listing {
    display: flex;
    gap: 20px;
    width: 700px;
    cursor: pointer;
}

.news-article-listing-text-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.news-articles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(700px, 1fr)); /* Adjust column width as needed */
    gap: 20px;
    margin: 0 auto; /* Center the grid horizontally */
}

.news-article-listing-title {
    font-size: 24px;
    font-family: var(--main-font);
    font-weight: 500;
}

.news-article-listing-description {
    font-size: 16px;
    font-family: var(--main-font);
    font-weight: 500;
}

.news-article-listing-date {
    font-size: 16px;
    font-family: var(--main-font);
    font-weight: 500;
    color: var(--title2-color)
}

.news-article-list-title {
    font-size: 35px;
    color: var(--primary-color);
    font-family: var(--main-font);
    font-weight: 500;
}

.news-article-listing-img {
    width: 196px;
    height: 148px;
    border-radius: 5px;
    object-fit: cover;
}

@media(max-width: 800px) {

    .news-img {
        display: none;
    }

    .news-button-container {
        flex-direction: column;
        padding-right: 1rem;
    }

    .news-button {
        padding-top: 0;
        padding-left: 1rem;
    }

}

@media(max-width: 755px) {

    .news-header {
        padding: 2rem 1rem;
        width: calc(100% - 2rem);
    }

    .news-left-header {
        width: 100%;
    }

    .news-h1 {
        font-size: 35px;
    }

    .news-article-list {
        padding: 2rem 1rem;
        width: calc(100% - 2rem);
    }

    .news-article-listing {
        width: 350px;
    }

    .news-article-listing-img {
        width: 100px;
        height: 100px;
    }

    .news-article-listing-title {
        font-size: 20px;
    }

    .news-article-listing-date {
        font-size: 12px;
    }

    .news-article-listing-description {
        font-size: 12px;
    }

    .news-articles {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Adjust column width as needed */
        gap: 20px;
        margin: 0 auto; /* Center the grid horizontally */
    }

    .news-article-list-title {
        font-size: 30px;
    }
    
}
