.modal-root {
    padding-right: 15px;
}

.login-forgot-password {
    color: var(--title2-color);
    font-family: var(--main-font);
    font-weight: 500;
    font-size: 16px;
    margin-left: 3px;
    margin-top: 15px;
    text-transform: none;
}

.modal-button-wrapper {
    width: 100%;
    padding-left: 0.5rem;
}

.login-forgot-container {
    margin-top: 15px;
}

.login-account-create {
    text-align: center;
    font-family: var(--main-font);
    font-size: 16px;
    font-weight: 500;
}

.login-grey-text {
    color: var(--title2-color);
    cursor: pointer;
}

.login-green-text {
    color: var(--green-shadow);
    cursor: pointer;
}

.spinner-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.account-creation-header {
    text-align: center;
    font-family: var(--main-font);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.account-creation-title {
    color: var(--title-color);
    font-size: 35px;
}

.account-creation-description {
    color: var(--title2-color);
    font-size: 16px;
}