.application-dogcard {
    width: 368px;
    height: 524px;
    border-radius: 16px;
    border-style: solid;
    border-width: 2px;
    border-color: var(--grey-border);
    box-shadow: 0px 4px 0px var(--grey-border);
    font-family: var(--main-font);
    cursor: pointer;
    margin-right: 1.5vw;
    background-color: white;
}


.dogcard:hover {
    border-color: var(--green-shadow);
    box-shadow: 0px 4px 0px var(--green-shadow);
}

.application-dogcard-application-container {
    position: absolute;
    width: 306px;
    height: 35px;
    background-color: #fff;
    border-radius: 15px 15px 0 0;
    font-size: 11px;
    font-weight: 500;
    padding-top: 0.6rem;
    padding-left: 1rem;
    box-sizing: border-box;
  }

.red {
    background-color: #bd4343;
    color: white;
}

.yellow {
    background-color: #FFBF00;
    color: #000;
}

.green {
    background-color: #39881B;
    color: white;
}

.application-status-text {
    margin-top: 20px;
}

.application-dogcard-image-wrapper {
    position: relative;
}

.application-dogcard-link {
    color: inherit;
}

.favorite-icon {
    position: absolute;
    right: 1px;
    top: 2px;
    transition: transform 0.3s ease-in-out;
  }
  
  .favorite-animate {
    transform: scale(1.2);
  }




.application-dogcard-image {
    width: 368px;
    height: 250px;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-width: 2px;
    border-bottom-color: var(--grey-border);
    border-bottom-style: solid;
}

.favorite-icon {
    position: absolute;
    right: 1px;
    top: 2px;
}

.application-dogcard-info {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.application-dogcard-name {
    font-weight: 500;
    font-size: 25px;
}

.application-dogcard-info-container {
    margin-top: 5px;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
}

.application-dogcard-description {
    color: var(--title2-color);
    font-size: 14px;
}

.application-dogcard-info-text {
    margin-left: 8px;
    font-weight: 500;
    font-size: 16px;
    /* margin-top: -20px; */
}

.weight {
    margin-bottom: 0;
}




.application-dogcard-info-age {
    margin-left: 12px;
    font-weight: 500;
    font-size: 16px;
    /* margin-top: -20px; */
}

.application-dogcard-name-heart {
    display: flex;
    justify-content: space-between;
}

.application-dogcard-favorite {
    margin-top: 3px;
    margin-right: 2px;
}

.application-dogcard-cta {
    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: var(--grey-border);
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: fit-content;
    gap: 3px;
}

.application-dog-card-know-more {
    display: flex;
    align-items: center;
}

.application-dogcard-cta-text {
    font-weight: 500;
    color: var(--green-shadow);
    font-size: 16px;
    margin-right: 5px;
}

.application-dogcard-cta-icon {
    margin-top: 8px;
}

.application-dogcard-info-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .application-dogcard-modal {
    width: 320px;
    height: 655px;
    border-radius: 9.95px;
    border-style: solid;
    border-width: 0px;
    border-color: rgba(var(--grey-border), 0);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-width: 0.5px;
    border-color: var(--grey-border);
    font-family: var(--main-font);
    cursor: pointer;
    margin-right: 1.5vw;
    background-color: white;
}

.application-dogcard-image-modal {
    width: 320px;
    height: 325px;
    object-fit: cover;
    border-top-left-radius: 8.95px;
    border-top-right-radius: 8.95px;
    border-bottom-width: 0px;
    border-color: rgba(var(--grey-border), 0);
    border-bottom-style: solid;
}
  

@media (max-width: 768px) {

    .application-dogcard-modal {
        width: 320px;
        height: 535px;
        border-radius: 9.95px;
        border-style: solid;
        border-width: 0px;
        border-color: rgba(var(--grey-border), 0);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        border-width: 0.5px;
        border-color: var(--grey-border);
        font-family: var(--main-font);
        cursor: pointer;
        margin-right: 1.5vw;
        background-color: white;
    }

    .application-dogcard-image-modal {
        width: 320px;
        height: 325px;
        object-fit: cover;
        border-top-left-radius: 8.95px;
        border-top-right-radius: 8.95px;
        border-bottom-width: 0px;
        border-color: rgba(var(--grey-border), 0);
        border-bottom-style: solid;
    }

    .application-dogcard {
        width: 260px;
        height: 430px;
        border-radius: 9.95px;
        border-style: solid;
        border-width: 0px;
        border-color: rgba(var(--grey-border), 0);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        border-width: 0.5px;
        border-color: var(--grey-border);
        font-family: var(--main-font);
        cursor: pointer;
        margin-right: 1.5vw;
    }

    .application-dogcard-app {
        width: 260px;
        height: 380px;
        border-radius: 9.95px;
        border-style: solid;
        border-width: 0px;
        border-color: rgba(var(--grey-border), 0);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        border-width: 0.5px;
        border-color: var(--grey-border);
        font-family: var(--main-font);
        cursor: pointer;
    }

    .application-dogcard-description {
        display: none;
    }

    .application-dogcard-mobile-stretch {
        width: 300px;
        justify-self: center;
        margin-left: -1.8rem;
    }

    .application-dogcard-application-container {
        position: absolute;
        width: 300px;
        height: 35px;
        border-radius: 7px 7px 0 0;
        font-size: 11px;
        font-weight: 500;
        padding-top: 0.6rem;
        padding-left: 1rem;
        box-sizing: border-box;
      }

    .application-dogcard-image-mobile-stretch {
        width: 300px !important;
    }

    .application-dogcard-image {
        width: 260px;
        height: 235px;
        object-fit: cover;
        border-top-left-radius: 8.95px;
        border-top-right-radius: 8.95px;
        border-bottom-width: 0px;
        border-color: rgba(var(--grey-border), 0);
        border-bottom-style: solid;
    }

    .application-dogcard-info-container {
        margin-top: 5px;
        display: flex;
        width: fit-content;
        align-items: center;
        justify-content: center;
    }

    .application-dogcard-info {
        display: flex;
        flex-direction: column;
        padding: 10px;
        height: fit-content;
    }

    .application-dogcard-info-male {
        display: flex;
        flex-direction: column;
        padding: 2px 10px 10px 10px;
        height: fit-content;
    }

    .application-dogcard-name {
        font-weight: 600;
        font-size: 16px;
    }

    .application-dogcard-info-text {
        margin-left: 8px;
        font-weight: 500;
        font-size: 13px;
        /* margin-top: -20px; */
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .application-dogcard-image-wrapper {
        background-color: rgba(0, 0, 0, 0); /* Black with 50% transparency */
    }




    .application-dogcard-info-container .weight {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .application-dogcard-info-age {
        margin-left: 12px;
        font-weight: 500;
        font-size: 13px;
        /* margin-top: -20px; */
    }

    .application-dogcard-cta-text {
        font-weight: 500;
        color: var(--green-shadow);
        font-size: 13px;
        margin-right: 5px;
    }

    .application-dogcard-cta {
        border-top-style: solid;
        border-top-width: 2px;
        border-top-color: var(--grey-border);
        justify-content: center;
        align-items: center;
        text-align: left;
        padding-top: 4px;
        display: flex;
    }

}
