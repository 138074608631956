.deals-page-root {
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

.deals-blurred-content {
    filter: blur(10px);
}


.deals-root {
    width: calc(100% - 2rem);
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    padding: 2rem 1rem;
    font-family: var(--main-font);
    justify-content: center;
}

.deals-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deals-footer-button {
    cursor: pointer;
}

.deals-modal-content {
    width: 345px;
    height: 468.31px;
    background-color: white;
    z-index: 999;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deals-modal-logo {
    position: absolute;
    margin-top: 20px;
}

.deals-modal-image {
    width: 345px;
    height: 226.31px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    object-fit: cover;
}
.deals-modal-button-container {
    width: 125px;
}

.deals-container-left {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.deals-container-right {
    padding-top: 10rem;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.deals-modal-text {
    color: var(--title2-color);
    padding: 0 1rem;
    font-weight: 500;
}

.deals-info-button {
    padding-top: 5px;
    position: absolute;
    right: 1rem;
}

.deals-logo {
    width: 180px;
}

.deals-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.deals-brand-text {
    margin-top: 2px;
    font-size: 20px;
    color: var(--primary-color);
}

.deals-description {
    font-weight: 500;
    font-size: 13px;
    color: var(--primary-color);
    /* margin-left: 3px; */
}
.deals-description-container {
    margin-top: -5px;
}

.deals-page-title {
    font-size: 35px;
    font-weight: 500;
    margin-top: 1rem;
    display: none;
}

.deals-page-title-desktop {
    font-size: 35px;
    font-weight: 500;
    margin-top: 1rem;
}

.deals-benefit-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--title2-color);
}

.deals-benefit-container1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* justify-content: center; */
    align-items: center;
    width: fit-content;
}

.deals-benefit-container-shelter {
    display: flex;
    flex-direction: column;
    gap: 34px;
    /* justify-content: center; */
    align-items: center;
    width: fit-content;
}

.deals-benefit-container2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    /* align-items: center; */
    width: fit-content;
}

.deals-product-head-section {
    display: flex;
    gap: 10px;
}

.deals-product-head-section2 {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.deals-product-image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 140px;
}

.deals-product-image-object {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.deals-product-name {
    color: var(--title2-color);
    font-weight: 500;
    font-size: 24px;
    margin-top: 15px;
    display: none;
}

.deals-product-name-desktop {
    color: var(--title2-color);
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 5rem;
}

.deals-price-container {
    display: flex;
    gap: 5px;
    align-items: center;
}

.deals-price {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 24px;
}

.deals-og-price {
    text-decoration: line-through;
    color: var(--title2-color);
}

.deals-product-description {
    color: var(--title2-color);
}

.deals-product-description-bold {
    font-weight: 600;
}

.deals-footer {
    font-family: var(--main-font);
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem 8.5rem 2rem;
    color: var(--title2-color);
    font-weight: 600;
    bottom: 0;
}

.deals-meter {
    color: var(--primary-color);
    font-size: 14px;
}

.deals-meter-container {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.deals-contact-buttons {
    display: flex;
    gap: 15px;
}

.deals-mobile-cta {
    font-family: var(--main-font);
    display: flex;
    flex-direction: column;
    bottom: 0;
    position: fixed;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background-color: white;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    z-index: 998;
    gap: 10px;
  }

  .deals-mobile-cta-bottom {
    font-family: var(--main-font);
    display: flex;
    bottom: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
    background-color: white;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    z-index: 1999;
    margin-bottom: -0.5rem;
  }

  .deals-mobile-cta-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
  }


  @media(max-width: 780px) {
    .deals-page-root {
        /* display: flex; */
        justify-content: center;
        align-items: center;
    }
    
    .deals-blurred-content {
        filter: blur(10px);
    }
    
    
    .deals-root {
        width: calc(100% - 2rem);
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem;
        font-family: var(--main-font);
    }
    
    .deals-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .deals-footer-button {
        cursor: pointer;
    }
    
    .deals-modal-content {
        width: 345px;
        height: 468.31px;
        background-color: white;
        z-index: 999;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .deals-modal-logo {
        position: absolute;
        margin-top: 20px;
    }
    
    .deals-modal-image {
        width: 345px;
        height: 226.31px;
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        object-fit: cover;
    }
    .deals-modal-button-container {
        width: 125px;
    }
    
    .deals-container-left {
        display: flex;
        flex-direction: column;
    }
    
    .deals-modal-text {
        color: var(--title2-color);
        padding: 0 1rem;
        font-weight: 500;
    }
    
    .deals-info-button {
        padding-top: 5px;
        position: absolute;
        right: 1rem;
    }
    
    .deals-logo {
        /* width: 96px; */
    }
    
    .deals-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    
    .deals-brand-text {
        margin-top: 2px;
        font-size: 20px;
        color: var(--primary-color);
    }
    
    .deals-description {
        font-weight: 500;
        font-size: 13px;
        color: var(--primary-color);
        /* margin-left: 3px; */
    }
    .deals-description-container {
        margin-top: -5px;
    }
    
    .deals-page-title {
        font-size: 35px;
        font-weight: 500;
        margin-top: 1rem;
        display: flex;
    }

    .deals-page-title-desktop {
        display: none;
    }

    .deals-product-name-desktop {
        display: none;
    }
    
    .deals-benefit-title {
        font-size: 16px;
        font-weight: 600;
        color: var(--title2-color);
    }
    
    .deals-benefit-container1 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* justify-content: center; */
        align-items: center;
        width: fit-content;
    }
    
    .deals-benefit-container-shelter {
        display: flex;
        flex-direction: column;
        gap: 34px;
        /* justify-content: center; */
        align-items: center;
        width: fit-content;
    }
    
    .deals-benefit-container2 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        /* align-items: center; */
        width: fit-content;
    }
    
    .deals-product-head-section {
        display: flex;
        gap: 10px;
    }
    
    .deals-product-head-section2 {
        display: flex;
        gap: 10px;
        margin-top: 5px;
    }
    
    .deals-product-image {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 18px;
    }
    
    .deals-product-image-object {
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .deals-product-name {
        color: var(--title2-color);
        font-weight: 500;
        font-size: 24px;
        margin-top: 15px;
        display: flex;
    }
    
    .deals-price-container {
        display: flex;
        gap: 5px;
        align-items: center;
    }
    
    .deals-price {
        color: var(--primary-color);
        font-weight: 600;
        font-size: 24px;
    }
    
    .deals-og-price {
        text-decoration: line-through;
        color: var(--title2-color);
    }
    
    .deals-product-description {
        color: var(--title2-color);
    }
    
    .deals-product-description-bold {
        font-weight: 600;
    }
    
    .deals-footer {
        font-family: var(--main-font);
        background-color: #FAFAFA;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 2rem 8.5rem 2rem;
        color: var(--title2-color);
        font-weight: 600;
    }
    
    .deals-meter {
        color: var(--primary-color);
        font-size: 14px;
    }
    
    .deals-meter-container {
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;
    }
    
    .deals-contact-buttons {
        display: flex;
        gap: 15px;
    }
    
    .deals-mobile-cta {
        font-family: var(--main-font);
        display: flex;
        flex-direction: column;
        bottom: 0;
        position: fixed;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        background-color: white;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
        z-index: 998;
        gap: 10px;
      }
    
      .deals-mobile-cta-bottom {
        font-family: var(--main-font);
        display: flex;
        bottom: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
        background-color: white;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
        z-index: 1999;
        margin-bottom: -0.5rem;
      }
    
      .deals-mobile-cta-left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 5px;
      }

      .deals-container-right {
        width: 100%;
        padding-top: 0;
      }

      .deals-container-left {
        width: 100%;
      }
      
    
  }