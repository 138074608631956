.filter-tag {
    padding: 4px 14px 4px 14px;
    font-weight: 500;
    font-size: 14px;
    border: 1.4px solid #DCDCDC;
    border-radius: 100px;
    width: fit-content;
    display: flex;
    background: white;
    margin-top: 5px;
    margin-right: 8px;

}

.filter-tag-icon {
    margin-left: 7px;
    margin-top: 2px;
    cursor: pointer;
}
