.counter {
    width: 50px;
    height: 30px;
    text-align: center;
    /* border: 2px solid #000; */
    border-style: solid;
    border-bottom: 4px solid;
    border-top: 2px solid;
    border-left: 2px solid;
    border-right: 2px solid;
    padding: 1px 5px 0px 5px;
    box-sizing: border-box;
    font-weight: 500;
    font-family: var(---main-font);
    font-size: 16px;
    border-radius: 50px;
    color: #000;
}