.discovery-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.discovery-root {
    /* padding: 0 5.1rem; */
    padding-top: 2rem;
    width: calc(100% - 8rem);
    max-width: 100rem;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: 0 auto; /* Add this line to center the elements */
}

.discovery-carousel-title {
    display: flex;
    align-items: center;
    gap: 5px;
}

.discovery-all-dogs-title {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 1rem;
}

.discovery-header {
    display: flex;
    flex-direction: column;
    font-family: var(--main-font);
}

.discovery-breadcrumbs {
    font-size: 13px;
    justify-content: space-between;
    display: flex;
    width: 15%;
    max-width: 150px;
    font-weight: 500;
}

.discovery-breadcrumbs .faded-text {
    color: var(--title2-color)
}

.discovery-header .content {
    padding-top: 25px;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.discovery-filters {
    display: flex;
    justify-content: space-around;
    padding-top: 5px;
    max-width: 80%;
}

.selected-filters {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
}

.discovery-header .title {
    font-weight: 500;
    font-size: 35px;
    padding-top: 10px;
}

.discovery-section-title {
    font-weight: 500;
    font-size: 35px;
    /* margin-left: 10px; */
}

.discovery-section-title-all {
    font-weight: 500;
    font-size: 35px;
}

.discovery-section-title2 {
    font-weight: 500;
    font-size: 16px;
    margin: 15px 0 25px 0;
    color: var(--title2-color)
}

.discovery-all-dogs {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
    grid-gap: 40px; 
  }

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.dropdown-options {
    animation: fadeIn 0.3s;
}

.fade-in {
    animation: fadeIn 0.3s;
}

.discovery-header .city-title {
    font-weight: 600;
    font-size: 35px;
    color: var(--primary-color);
}

.toggle-menu {
    /* Define necessary styles for the toggle button */
    /* The button should be hidden initially */
    display: none;
  }

  .discovery-root.expanded .discovery-filters {
    /* Define styles for expanded state */
    max-height: none;
  }

  .discovery-root.expanded .discovery-filters {
    /* Define styles for expanded state */
    max-height: none;
  }

@media (max-width: 1366px) { 
    .discovery-root {
        max-width: 77rem;
    }

    .discovery-filters {
        display: none;
      }

    .expanded {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-top: 1rem;
        overflow: visible;
        transition: max-height 0.3s ease;
      }
    
      .discovery-filters.expanded {
        max-height: none;
      }
    
      .toggle-menu {
        display: flex;
        justify-content: right;
        cursor: pointer;
        margin-top: 10px;
      }

}

@media (max-width: 768px) { 
    .discovery-root {
        /* padding: 0 5.1rem; */
        padding-top: 2rem;
        width: calc(100% - 2rem);
        /* max-width: 25rem; */
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 0 auto; /* Add this line to center the elements */
    }    

    .discovery-header .title {
        font-weight: 500;
        font-size: 24px;
        width: 80%;
    }

    .discovery-header .city-title {
        font-weight: 600;
        font-size: 24px;
        color: var(--primary-color);
    }

    .content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: unset;
    }

    .discovery-filters {
        display: none;
        margin-top: 10px;
      }

      .expanded {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        overflow: visible;
        transition: max-height 0.3s ease;
        gap: 10px;
      }

    .discovery-breadcrumbs {
        font-size: 13px;
        justify-content: space-between;
        display: flex;
        width: 50%;
        /* max-width: 1250px; */
        font-weight: 500;
    }

    .discovery-all-dogs {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(auto-fill, minmax(113px, 1fr)); 
        grid-gap: 40px; 
        margin-left: 15px;

    }

    .toggle-menu {
        display: flex;
        justify-content: right;
        cursor: pointer;
        margin-top: 10px;
        position: absolute;
        right: 10px;
      }
    
    
}

@media (max-width: 600px) { 
    .discovery-root {
        /* padding: 0 5.1rem; */
        padding-top: 1rem;
        width: 90%;
        max-width: 25rem;
        padding-right: 0;
        padding-left: 0;
        margin: 0 auto; /* Add this line to center the elements */
    }   

    .discovery-header .ant-divider-horizontal {
        margin: 5px 0 12px 0;
    }

    .discovery-filters {
        max-width: unset;
    }

    .content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: unset;
    }

    .discovery-header .content {
        margin-top: 0;
        padding-top: 0;
        width: unset;
        display: flex;
        /* justify-content: center; */
    }

    .discovery-filters {
        display: none;
      }

    .expanded {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-top: 1rem;
        overflow: visible;
        transition: max-height 0.3s ease;
      }

    .discovery-breadcrumbs {
        font-size: 13px;
        justify-content: space-between;
        display: flex;
        width: 50%;
        /* max-width: 1250px; */
        font-weight: 500;
    }

    .discovery-all-dogs {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(auto-fill, minmax(113px, 1fr)); 
        grid-gap: 30px; 
        margin-left: 10px;
    }

    .discovery-section-title {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
    }
        .discovery-section-title {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
    }

    .discovery-section-title2 {
        font-weight: 500;
        font-size: 14px;
        margin: 5px 0 10px 0;
        color: var(--title2-color)
    }
     
}