body {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  body::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for Webkit browsers */
  }

.shelters-root {
    font-family: var(--main-font);
    overflow-y: scroll;
    scroll-snap-type: y mandatory; 
    scroll-snap-stop: always; /* Allow scrolling before snap */
    height: 100vh; 
  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding-left: 4rem;
  padding-right: 4rem;
}

.shelters-step-arrow-down {
    display: none;
}

.shelters-root::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for Webkit browsers */
}
  
  .shelters-section {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 100vh; /* Each section takes up the full viewport height */
    scroll-snap-align: start; /* Snap each section to the top */
    padding: 1vh;
    flex-direction: column;
  }

.shelters-intro {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: 6vh;
}

.shelters-intro-title {
    font-weight: 500;
    font-size: 64px;
}

.shelters-description-container {
    width: 65rem;
    margin-bottom: 20px;
}

.shelters-cta-buttons {
    display: flex;
    gap: 5px;
}

.shelters-intro-description {
    font-size: 32px;
    color: var(--title2-color);
    font-weight: 500;
}


.shelters-partners {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    text-align: left;
    gap: 15px;
}

.statement-partner {
    display: flex;
    flex-direction: column;
    width: 400px;
    border-style: solid;
    border-color: var(--grey-border);
    border-radius: 20px;
    border-width: 1px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adjust the values for the desired shadow effect */
}

.partner-info {
    display: flex;
    gap: 20px;
}

.partner-text-info {
    display: flex;
    flex-direction: column;
}

.partner-img {
    height: 45px;
    width: 45px;
    border-radius: 10px;
}

.shelters-process-container {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    position: relative;
    padding-left: 4rem;
    padding-right: 4rem;
    width: 80%;
}

.partner-job-title {
    font-size: 13px;
    font-weight: 500;
}

.partner-statement {
    color: var(--title2-color);
    font-weight: 500;
    font-size: 13px;
}

.partner-name {
    font-size: 24px;
    font-weight: 500;
}

.partner-logos {
    width: 80% !important;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    padding: 4rem 0 0 0;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.shelters-step-icon {
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 0.9rem;
    border-radius: 30px;
}

.shelters-step-icon1 {
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 30px;
}

/* .shelters-section {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
} */

.shelters-section-title {
    font-weight: 500;
    font-size: 42px;
}

.shelters-section-description-container {
    width: 60rem;
}

.shelters-section-description {
    font-weight: 500;
    color: var(--title2-color);
    font-size: 21px;
    line-height: 26px;
}

.shelters-benefits-container {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    gap: 10%;
}

.shelters-benefit {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: center;
}

.shelters-process {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
    width: 30%;
}

.step-line-division {
    height: 2px;
    background-color: var(--green-shadow);
    width: 50%;
    margin-top: 20px;
}

.shelters-benefit-img {
    height: 50px;
    object-fit: cover;
}

.shelters-benefit-description {
    text-align: center;
    color: var(--title2-color);
    font-weight: 500;
}

.shelters-benefit-title {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 500;
}

.shelters-team-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1px;
    padding-top: 3rem;
    width: 100%;
    justify-items: center;
}

.shelters-team-entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.shelters-team-img {
    width: 200px;
    height: 200px;
    border-radius: 20px;
    object-fit: cover;
}

.shelters-team-name {
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
}

.shelters-team-position {
    color: var(--title2-color);
}

.questions-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 24rem);
    padding-top: 4rem;
}

.accordion-container {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
}

.question-accordion {
    border: solid var(--grey-border);
    border-top-width: 1px;
    border-left-width: 1px;
    border-radius: 15px;
    text-align: left;
    cursor: pointer;
}

.accordion-question {
    display: block;
    padding: 15px;
    font-weight: 500;
    font-size: 18px;
}

.accordion-answer {
    font-weight: 500;
    color: var(--title2-color);
    font-size: 16px;
}





.accordion-open .accordion-question::before {
    transform: rotate(45deg);
}

.accordion-content {
    display: none;
    padding: 0 15px 15px;
    max-height: 0; /* Start with a height of 0 */
    overflow: hidden;
    transition: max-height 0.3s ease-in-out; /* Add transition */
}

.accordion-open .accordion-content {
    display: block;
    max-height: 1000px; /* Adjust this value to a suitable maximum height */
}

@media (max-height: 900px) {
    .shelters-root {
        padding-left: 4rem;
        padding-right: 4rem;
        width: calc(100% - 8rem);
        overflow-y: none;
            scroll-snap-type: none; 
            height: fit-content; 
    scroll-snap-type: none;
        
    scroll-snap-type: none;
    }

    .shelters-section {
        height: fit-content;
        padding: 7vh 0;
    }
}

@media (max-width: 1055px) {
    .shelters-root {
        padding: 0 4rem;
    }
.shelters-description-container {
    width: 50rem;
    margin-bottom: 20px;
}


}
@media (max-width: 900px) {
    .shelters-benefits-container {
        flex-direction: column;
    }
}

@media (max-width: 900px) {
    .shelters-benefits-container,
    .shelters-team-container {
        flex-direction: column;
        gap: 15px;
    }
}

@media (max-width: 890px) {

    .shelters-root {
        padding-left: 1rem;
        padding-right: 1rem;
        width: calc(100% - 2rem);

            overflow-y: none;
            scroll-snap-type: none; 
            height: fit-content; 
    scroll-snap-type: none;
    }

    .shelters-section {
        height: fit-content;
        padding-top: 7vh;
        padding-bottom: 7vh;
    }

    .shelters-intro-title {
        font-size: 34px;
    }

    .shelters-intro-description {
        font-size: 21px;
    }

    .shelters-description-container {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 90%;
    }

    .shelters-team-container {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Two columns in the second row */
    }

    .shelters-cta-buttons {
        flex-direction: column;
        gap: 15px;
    }

    .shelters-section-title {
        font-size: 32px;
    }

    .shelters-section-description {
        font-size: 16px;
    }

    .shelters-section-description-container {
        width: 90%;
    }

    .shelters-team-entry {
        padding-bottom: 50px;
    }

    .shelters-team-img {
        width: 100px;
        height: 100px;
    }

    .shelters-partners {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .statement-partner {
        width: calc(100% - 2rem);
    }

    .partner-logos {
        width: calc(100% - 2rem);
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 15px;
        padding: 4rem 0 0 0;
        justify-content: center;
        align-items: center;
        justify-items: center;
    }

    .shelters-process-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .shelters-step-arrow-down {
        display: flex;
        width: calc(100%);
        justify-content: center;
        align-items: center;
    }

    .shelters-step-icon {
        display: none;
    }

    .shelters-step-icon1 {
        display: none;
    }

    .step-line-division {
        display: none;
    }

    .shelters-process {
        width: calc(100% - 2rem);
    }

    .questions-container {
        width: calc(100% - 2rem) !important;
    }
}
