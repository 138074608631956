.home-root {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.perks-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  max-width: 400px;
}

.perk {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.perk-icon {
  width: 60px;
  height: 50px;
}

.perk-text {
  font-weight: 400;
  font-size: 18px;
  color: var(--title2-color);
}

.plate {
  padding-right: 5px;
}

.toy {
  padding-right: 5px;
}

.home-content {
  /* padding: 8.5rem 10.1rem 0 10.1rem; */
  padding-top: 8.5rem;
  /* padding-right: 1vh;
  padding-left: 1vh; */
  width: 100%;
  justify-content: center;
  font-family: var(--main-font);
  display: flex;
  flex-direction: column;
  max-width: 99rem;
  margin: 0 auto;
}

.home-section1 {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  
  max-width: 99rem;
  width: 100%;
}

.home-section2 {
  padding-top: 70px;
  padding-bottom: 50px;
  justify-content: center;
  
  max-width: 99rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}



.home-section4 {
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  max-width: 99rem;
  align-items: center;
  display: flex;
}

.home-section5 {
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section1-title {
  color: var(--title-color);
  font-weight: 500;
  font-size: 75px;
  line-height: 86.25px;
  text-align: left;
}

.section1-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: left;
  gap: 5px;
}

.section1-title2 {
  color: var(--title2-color);
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
}

.section1-left {
  margin-top: 40px;
  /* margin-right: 10px; */
  width:850px;
  margin-right: 50px;
}

.section1-right {
  /* position:relative; */
}

.section1-main-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section1-scroll-message {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section1-scroll-text {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.section3-title {
  color: var(--title-color);
  font-weight: 500;
  font-size: 50px;
  line-height: 57.5px;
  text-align: center;
}

.section3-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: left;
  gap: 5px;
}

.section3-title2 {
  color: var(--title2-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.section3-left {
  margin-top: 40px;
  margin-left: 2vw;
  width: 100%;
  max-width: 45rem;
}

.section3-right {
  width: 22%;
}

.home-section3 {
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 99rem;
}

.section3-main-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 250rem;
}

.section4-main-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section3-scroll-message {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section3-scroll-text {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.section2-dogcards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 20px;
}

.section2-title {
  text-align: center;
  font-weight: 500;
  font-size: 50px;
  color: var(--title-color);
  margin-bottom: 70px;
}

.section2-button {
  /* width: 100px; */
  margin-top: 50px;
}

.section2-button-container {
  display: flex;
  justify-content: center;
}

.section4-left {
  margin-top: 40px;
  padding-right: 10rem;
}

.section4-right {
  margin-left: 3vw;
  margin-top: 11vh;
}

.section4-title {
  color: var(--title-color);
  font-weight: 500;
  font-size: 50px;
  line-height: 57.5px;
  text-align: center;
}

.section4-title2 {
  color: var(--title2-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.section5-title {
  color: var(--title-color);
  font-weight: 500;
  font-size: 50px;
  line-height: 57.5px;
  text-align: center;
}

.section5-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: left;
  gap: 5px;
}

.section5-title2 {
  color: var(--title2-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.section5-left {
  margin-top: 40px;
  padding-right: 10rem;
}

.section5-right {
}

.section5-main-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section5-scroll-message {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section5-scroll-text {
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}



/* Media Queries */


@media (max-width: 1480px) { 
  .home-content {
      max-width: 77rem;
  }
  .section3-main-content {
    width: 150rem;
  }
}

@media (max-width: 1200px) {
  .home-content {
    /* padding: 6rem 8.1rem 0 8.1rem; */
  }

  .section1-left,
  .section3-left,
  .section4-left,
  .section5-left {
    /* padding-right: 6rem; */
  }

  .section1-right,
  .section3-right,
  .section4-right,
  .section5-right {
    /* margin-right: 3vw; */
  }

  .section1-left {
    margin-bottom: 3rem;
  }

  .section3-left {
    max-width: 60rem;
    margin-bottom: 5rem;
  }

  .section5-main-content {
    flex-direction: column-reverse;
  }

  .section5-left {
    padding-left: 2rem;
  }


  .section1-main-content,
  .section3-main-content {
    flex-direction: column;
  }

  .section3-main-content {
    width: 150rem;
  }

  .section4-main-content {
    flex-direction: column;
  }
}


@media (max-width: 992px) {
  .home-content {
    /* padding: 4rem 5.1rem 0 5.1rem; */
    padding-top: 1rem;
  }

  .section1-left,
  .section3-left,
  .section4-left,
  .section5-left {
    /* padding-right: 4rem; */
  }

  .section3-left {
    width: 600px;
    margin: 0;
    padding: 0;
  }

  .section3-right {
    margin-top: 50px;
    width: 400px;
    margin-left: 100px;
  }

  .section3-title {
    font-size: 35px;
    line-height: 40px;
  }

  .section1-scroll-message {
    display: none;
  }



  .section1-left {
    width: 600px;
    padding-bottom: 5rem;
  }

  .home-section1 {
    padding-top: 1rem;
  }

  .home-section4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .home-section5 {
    max-width: 99rem;
  }

  .home-section3 {
    padding-bottom: 0;
  }

  .home-section5 {
    padding: 0;
  }

  .section1-right,
  .section3-right,
  .section4-right,
  .section5-right {
    /* margin-right: 2.5vw; */
  }

  .section1-title {
    font-size: 60px;
    line-height: 68.25px;
  }

  .section1-title2,
  .section3-title2,
  .section5-title2 {
    font-size: 30px;
    line-height: 36px;
  }

  .section3-main-content {
    width: 150rem;
}
}



@media (max-width: 768px) {
  .home-content {
    /* padding: 3rem 2.5rem 0 2.5rem; */
  }

  .section1-img {
    width: 350px;
  }

  .section4-img {
    width: 250px;
  }

  .section3-left {
    width: 300px;
  }

  .section5-img {
    width: 350px;
  }

  .section4-left {
    padding: 0;
  }

  .section4-right {
    width: 350px;
  }

  .section4-title {
    font-size: 35px;
    line-height: 40.95px;
  }

  .section4-title2 {
    line-height: 36px;
  }

  .section5-left {
    width: 350px;
    margin-bottom: 50px;
    padding: 0;
  }

  .section5-title {
    font-size: 35px;
    line-height: 40.95px;
  }

  .section5-title2 {
    line-height: 36px;
  }


  .section3-main-content {
    margin-left: -50px;
  }



  .section4-main-content {
    flex-direction: column;
  }



  .section1-left,
  .section3-left,
  .section4-left,
  .section5-left {
    /* padding-right: 2rem; */
  }

  .section1-right,
  .section3-right,
  .section4-right,
  .section5-right {
    /* margin-right: 2vw; */
  }

  .section1-title {
    font-size: 45px;
    line-height: 51.75px;
  }

  .section1-title2,
  .section3-title2,
  .section5-title2 {
    font-size: 24px;
    line-height: 30px;
  }

  .section2-dogcards {
    flex-direction: column;
    align-items: center;
  }

  .section2-button-container {
    margin-top: 30px;
  }

  .section2-button {
    width: 120px;
  }
}

@media (max-width: 700px) {
  .home-content {
    /* padding: 2rem 1.5rem 0 1.5rem; */
  }

  .section1-left {
    width: 300px;
  }

  .section1-left,
  .section3-left,
  .section4-left,
  .section5-left {
    /* padding-right: 1.5rem; */
  }

  .section1-right,
  .section3-right,
  .section4-right,
  .section5-right {
    /* margin-right: 1.5vw; */
  }

  .section1-title {
    font-size: 40px;
    line-height: 45px;
  }

  .section1-title2,
  .section3-title2,
  .section5-title2 {
    font-size: 20px;
    line-height: 24px;
  }
}

.arrow-bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
