.dropdown {
    display: flex;
    position: relative;
    justify-self: right;
    font-family: var(--main-font);
    font-weight: 500;
    font-size: 16px;
    width: 260px;
    border: 2px solid var(--grey-border);
    box-shadow: 0px 3px 0px var(--grey-border);
    border-radius: 8px;
    height: 38px;
    padding: 8px 16px;
    box-sizing: border-box;
    justify-content: space-between;
    cursor: pointer;
    margin-left: 5px;
    margin-bottom: 10px;
    align-items: center;
}

.dropdown-open {
    border: 2px solid var(--green-shadow);
    box-shadow: 0px 3px 0px var(--green-shadow);
}

.dropdown-input {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.dropdown-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-title {
    margin-left: 7px;
}

.dropdown-open-indicator {
    /* margin-top: -2px; */
}

.dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid var(--grey-border);
    border-radius: 8px;
    padding: 8px 0;
    z-index: 10;
}

.dropdown-options li {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    white-space: wrap;
    word-wrap: wrap;
}

.dropdown-options li:hover {
    background-color: var(--grey-border);
}

/* .dropdown-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  outline: none;
  transition: border-color 0.3s;
} */

.dropdown-checkbox {
  position: absolute;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: white;
    border: 2px solid var(--green-shadow); 
    border-radius: 3px;
    outline: none;
    padding: 5px;
    transition: background-color 0.3s;
  }
  
  /* Custom checkbox when checked */
  .dropdown-checkbox:checked {
    background-color: var(--green-shadow); /* Background color when checked */
  }
  
  /* Checkbox animation */
  .dropdown-checkbox:before {
    content: "\2714";
    position: absolute;
    top: 40%;
    left: 70%;
    width: 15px;
    height: 15px;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: bold;
    color: white; /* Tick color */
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
  }
  
  .dropdown-checkbox:checked:before {
    opacity: 1;
  }
  
  /* Label style */
  .dropdown-label {
    margin-left: 10px;
    /* Add additional styles as per your requirements */
  }


@media (max-width: 768px) {
  .dropdown {
    width: unset;
    align-items: center;
    margin-left: 0;
    margin-bottom: 0;
  }

  .dropdown-title {
    margin-right: 5px;
  }

  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: auto;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid var(--grey-border);
    border-radius: 8px;
    padding: 8px 0;
    z-index: 10;
    white-space: nowrap;
    overflow-x: hidden;
  }
}
  