.onboarding {
    display: flex;
    flex-direction: row;
    height: 100%;
    font-family: var(--main-font);
}

.onboarding-left {
    width: 64%;
    height: 100vh;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.right {
    width: 36%;
    font-weight: 500;
    color: white;
    height: 100vh;
    background: linear-gradient(90deg, rgba(149,210,88,1) 0%, rgba(93,200,0,1) 51%, rgba(149,210,88,1) 100%);
    display: flex;
    flex-direction: column;
    position: relative;
}

.onboarding-right-header {
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 5vh;
}

.onboarding-left-header {
    padding-left: 4rem;
    padding-right: 4rem;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.onboarding-button {
    width: 100%;
    max-width: 350px;
}

.onboarding-right-title {
    font-size: 50px;
    line-height: 57.5px;
    
}

.onboarding-right-description {
    font-size: 35px;
    line-height: 40.95px;
}

.onboarding-left-title {
    font-size: 35px;
    line-height: 40.95px;
    color: var(--title-color);
}

.onboarding-left-description {
    font-size: 16px;
    line-height: 21.6px;
    color: var(--title2-color);
}

.onboarding-illustration-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    overflow: hidden;
    bottom: 0;
}

.onboarding-form-container-bubbles {
    max-height: 70vh;
    overflow-y: auto;
}

.onboarding-skip-container {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.onboarding-skip-text {
    text-align: center;
    color: var(--green-message);
    cursor: pointer;
}
.onboarding-skip-icon {
    margin-left: 7px;
    margin-top: 2px;
}

.onboarding-lower-title {
    font-weight: 600;
    font-size: 16px;
}

.steps-container {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-bottom: 50px;
}

.onboarding-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.onboarding-bubbles {
    padding-top: 20px;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
  }

  
  
  .onboarding-form-container-bubbles::-webkit-scrollbar {
    width: 2px;
  }
  
  .onboarding-form-container-bubbles::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
  }
  
  .onboarding-form-container-bubbles::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 4px;
  }
  
  .onboarding-form-container-bubbles::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover-color);
  }

  @media (max-width: 992px) {

    .onboarding {
        padding-bottom: 100px;
    }

    .right {
        width: 0%;
    }
    .onboarding-left {
        width: 100%;
    }
    .onboarding-illustration {
        width: 0px;
    }

    .onboarding-right-header {
        width: 0px;
        height: 0px;
        display: none;
    }
    .onboarding-form-container {
    }
    .onboarding-left-header {
        margin-top: 40px;
        padding-left: 1rem;
        padding-right: 3rem;
        width: calc(100% - 4rem);
        max-width: unset;
    }

    .onboarding-left-title {
        font-size: 23px;
        line-height: 40.95px;
        color: var(--title-color);
    }

    .onboarding-left-description {
        font-size: 16px;
        line-height: 20px;
    }

    .onboarding-bubbles {
        padding-top: 20px;
        width: 80vw;
        flex-wrap: wrap;
        display: flex;
        overflow-y: auto;
        max-height: 20vh;
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
      }
  }