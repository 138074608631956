.deals-confirm-title {
    font-weight: 500;
    font-size: 24px;

}

.deals-checkout-confirm-textarea-container {
    width: calc(100% - 1rem);
}

.deals-checkout-confirm-buttons-container {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.deals-root-confirm {
    width: calc(100% - 2rem);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    font-family: var(--main-font);
    justify-content: center;
}

.deals-page-root-confirm {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding: 0 30rem;
}


@media(max-width: 780px) {
    .deals-page-root-confirm {
        padding: 0;
    }
}
