.checkout {
    display: flex;
    flex-direction: row;
    height: 100%;
    font-family: var(--main-font);
    overflow: hidden;
}

.payment-menu {
    display: flex;
    gap: 15px;
}

.checkout-left {
    width: 50%;
    /* height: 100vh; */
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.deals-checkout-input-container {
    margin-bottom: 1rem;
}

.deals-checkout-cart-selector-button {
    color: white;
    font-size: 14px;
    background-color: var(--primary-color);
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.deals-checkout-cart-data {
    font-weight: 500;
}

.deals-checkout-cart-counter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deals-checkout-cart-selector-button2 {
    color: white;
    font-size: 14px;
    background-color: var(--primary-color);
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.deals-checkout-cart-line2 {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.deals-checkout-cart-line3 {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.deals-checkout-og-price {
    color: #DCDCDC;
    text-decoration: line-through;
}

.deals-checkout-max-indicator {
    font-style: italic;
    color: var(--title2-color);
}


.deals-checkout-cart {
    /* width: calc(100% - 1rem); */
    max-width: 345px;
    display: flex;
    max-height: 122px;
    border-radius: 20px;
    border-style: solid;
    border-width: 2px 2px 4px 2px;
    border-color: var(--primary-color);
    padding: 0.5rem;
    align-items: center;
    gap: 10px;

}

.ant-select {
    max-height: 45px !important;
    overflow: visible !important;
    height: 45px;
    width: 100% !important;
}

.deals-checkout-cart-quantity-selector {
    display: flex;
    border-width: 1px;
    border-style: solid;
    width: 96px;
    justify-content: space-around;
    border-radius: 15px;
    height: 24px;
    border-color: var(--primary-color);
}

.deals-checkout-cart-title-green {
    color: var(--primary-color);
}

.deals-checkout-cart-image {
    width: 72px;
    height: 72px;
    object-fit: cover;
    border-radius: 100px;
    border-style: solid;
    border-width: 1px;
    border-color: #DCDCDC;
}

.required-star {
    color: red;
}

.map-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.affirm-disclaimer {
    font-size: 13px;
    font-weight: 400;
    color: #4F5B76;
    width: 100%;   
}


.checkout-card-details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
}

.affirm-link {
    text-decoration: none;
}

.checkout-right {
    width: 50%;
    font-weight: 500;
    color: white;
    height: 100vh;
    background: white;
    display: flex;
    flex-direction: column;
    position: relative;
}

.checkout-payment-breakdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.checkout-price-entry {
    display: flex;
    justify-content: space-between;
    
}

.product-name {
    font-size: 13px;
    font-weight: 600;
    max-width: 155px;
    color: var(--grey-labels);
}

.product-price {
    font-size: 18px;
    font-weight: 600;
    color: var(--grey-labels);
}


.dogprofile-map-checkout {
    width: 100%;
    height: 100%;
  }

.checkout-right-header {
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 5vh;
}

.checkout-left-header {
    padding-left: 4rem;
    padding-right: 4rem;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto; /* Use 'auto' instead of 'scroll' to hide the scrollbar when not needed */
    padding-bottom: 5vh;
    padding-top: 5vh;
    /* Customize the scrollbar styles */
    scrollbar-width: thin; /* Set the width of the scrollbar */
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track); /* Set the color of the scrollbar thumb and track */
  }
  
  /* Define the scrollbar thumb and track colors */
  .checkout-left-header::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  .checkout-left-header::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb); /* Color of the scrollbar thumb */
    border-radius: 3px; /* Rounded corners for the scrollbar thumb */
  }
  
  .checkout-left-header::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover); /* Color of the scrollbar thumb on hover */
  }
  
  .checkout-left-header::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track); /* Color of the scrollbar track */
  }
  

.checkout-button {
    width: 100%;
    margin-top: 15px;
}

.checkout-right-title {
    font-size: 50px;
    line-height: 57.5px;
    
}

.checkout-right-description {
    font-size: 35px;
    line-height: 40.95px;
}

.checkout-left-title {
    font-size: 35px;
    line-height: 40.95px;
    color: var(--title-color);
}

.checkout-left-description {
    font-size: 16px;
    line-height: 21.6px;
    color: var(--title2-color);
}

.checkout-illustration-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    overflow: hidden;
    bottom: 0;
}

.checkout-form-container-bubbles {
    max-height: 70vh;
    overflow-y: auto;
}

.checkout-skip-container {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.checkout-skip-text {
    text-align: center;
    color: var(--green-message);
    cursor: pointer;
}
.checkout-skip-icon {
    margin-left: 7px;
    margin-top: 2px;
}

.checkout-lower-title {
    font-weight: 600;
    font-size: 16px;
}

.steps-container {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-bottom: 50px;
}

.checkout-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.checkout-bubbles {
    padding-top: 20px;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
  }

  
  
  .checkout-form-container-bubbles::-webkit-scrollbar {
    width: 2px;
  }
  
  .checkout-form-container-bubbles::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
  }
  
  .checkout-form-container-bubbles::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 4px;
  }
  
  .checkout-form-container-bubbles::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover-color);
  }

  @media (max-width: 992px) {

    .checkout {
        padding-bottom: 100px;
    }

    .checkout-right {
        width: 0%;
    }
    .checkout-left {
        width: 100%;
    }
    .checkout-illustration {
        width: 0px;
    }

    .checkout-right-header {
        width: 0px;
        height: 0px;
        display: none;
    }

    .checkout-left-header {
        padding-left: 1rem;
        padding-right: 2rem;
    }

    .checkout-left-title {
        font-size: 23px;
        line-height: 40.95px;
        color: var(--title-color);
    }

    .checkout-left-description {
        font-size: 16px;
        line-height: 20px;
    }

    .checkout-bubbles {
        padding-top: 20px;
        width: 80vw;
        flex-wrap: wrap;
        display: flex;
        overflow-y: auto;
        max-height: 20vh;
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
      }
  }