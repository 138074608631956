.tag {
    padding: 4px 14px 0px;
    font-weight: 500;
    font-size: 14px;
    border: 1.4px solid #DCDCDC;
    border-radius: 100px;
    width: fit-content;
    display: flex;
    background: white;
    margin-top: 5px;
    margin-right: 8px;

}

.tag-icon {
    margin-left: 7px;
    margin-top: 1px;
    position: relative;
}


.tooltip {
  width: 150px;
  position: absolute;
top: 100%; /* Adjust this value to control the distance of the tooltip from the tag */
padding: 5px 20px;
left: 50%;
transform: translateX(-50%);
background-color: #000;
color: #fff;
font-size: 12px;
border-radius: 15px;
opacity: 0.9;
z-index: 9999;
text-align: left;
white-space: wrap;
height: fit-content
  }

  .tooltip::before {
    content: '';
    position: absolute;
    top: -8px; /* Adjust this value to control the distance of the spike from the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #000;
  }


  @media (max-width: 768px) {
    .tooltip {
      width: 150px;
      position: absolute;
    top: 100%; /* Adjust this value to control the distance of the tooltip from the tag */
    padding: 5px 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    font-size: 12px;
    border-radius: 15px;
    opacity: 0.9;
    z-index: 9999;
    text-align: left;
    white-space: wrap;
    height: fit-content
    }
  }