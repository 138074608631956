.dogcard {
    width: 306px;
    height: 535px;
    border-radius: 16px;
    border-style: solid;
    border-width: 2px;
    border-color: var(--grey-border);
    box-shadow: 0px 4px 0px var(--grey-border);
    font-family: var(--main-font);
    cursor: pointer;
    margin-right: 1.5vw;
}



.dogcard:hover {
    border-color: var(--green-shadow);
    box-shadow: 0px 4px 0px var(--green-shadow);
}

.dogcard-application-container {
    position: absolute;
    width: 306px;
    height: 35px;
    background-color: #fff;
    border-radius: 15px 15px 0 0;
    font-size: 11px;
    font-weight: 500;
    padding-top: 0.6rem;
    padding-left: 1rem;
    box-sizing: border-box;
  }

.red {
    background-color: #bd4343;
    color: white;
}

.yellow {
    background-color: #FFBF00;
    color: #000;
}

.green {
    background-color: #39881B;
    color: white;
}

.application-status-text {
    margin-top: 20px;
}

.dogcard-image-wrapper {
    position: relative;
}

.dogcard-badge-container {
    position: absolute;
    display: flex;
    width: fit-content;
    height: fit-content;
    bottom: 0;
    left: 0;
    padding-left: 10px;
    padding-bottom: 15px;
}

.dogcard-link {
    color: inherit;
}

.favorite-icon {
    position: absolute;
    right: 1px;
    top: 2px;
    transition: transform 0.3s ease-in-out;
  }
  
  .favorite-animate {
    transform: scale(1.2);
  }


.dogcard-image {
    width: 306px;
    height: 310px;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-width: 2px;
    border-bottom-color: var(--grey-border);
    border-bottom-style: solid;
}

.favorite-icon {
    position: absolute;
    right: 1px;
    top: 2px;
}

.dogcard-info {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 10px 20px;
}

.dogcard-name {
    font-weight: 500;
    font-size: 25px;
}

.dogcard-info-container {
    margin-top: 10px;
}

.dogcard-info-text {
    margin-left: 8px;
    font-weight: 500;
    font-size: 16px;
    margin-top: -20px;
}

.weight {
    margin-bottom: 12px;
}

.dogcard-info-age {
    margin-left: 12px;
    font-weight: 500;
    font-size: 16px;
    margin-top: -20px;
}

.dogcard-name-heart {
    display: flex;
    justify-content: space-between;
}

.dogcard-favorite {
    margin-top: 3px;
    margin-right: 2px;
}

.dogcard-cta {
    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: var(--grey-border);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 8px;
    display: flex;
}

.dogcard-cta-text {
    font-weight: 500;
    color: var(--green-shadow);
    font-size: 16px;
    margin-right: 5px;
}

.dogcard-cta-icon {
    margin-top: 8px;
}



.dogcard-info-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

@media (max-width: 768px) {
    .dogcard {
        width: 300px;
        height: 360px;
        border-radius: 9.95px;
        border-style: solid;
        border-width: 2px;
        border-color: var(--grey-border);
        box-shadow: 0px 4px 0px var(--grey-border);
        font-family: var(--main-font);
        cursor: pointer;
        margin-right: 1.5vw;
    }

    .dogcard-mobile-stretch {
        width: 170px;
        justify-self: center;
        /* margin-left: -1.8rem; */
    }

    .dogcard-application-container {
        position: absolute;
        width: 300px;
        height: 35px;
        border-radius: 7px 7px 0 0;
        font-size: 11px;
        font-weight: 500;
        padding-top: 0.6rem;
        padding-left: 1rem;
        box-sizing: border-box;
      }


    .dogcard-image {
        width: 170px;
        height: 192px;
        object-fit: cover;
        border-top-left-radius: 8.95px;
        border-top-right-radius: 8.95px;
        border-bottom-width: 2px;
        border-bottom-color: var(--grey-border);
        border-bottom-style: solid;
    }

    .dogcard-info-container {
        margin-top: 5px;
    }

    .dogcard-info {
        display: flex;
        flex-direction: column;
        padding: 5px 2px 4px 10px;
    }

    .dogcard-name {
        font-weight: 500;
        font-size: 18px;
    }

    .dogcard-info-text {
        margin-left: 8px;
        font-weight: 500;
        font-size: 13px;
        margin-top: -20px;
        overflow: hidden;
        text-overflow: ellipsis;
    }




    .dogcard-info-container .weight {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .dogcard-info-age {
        margin-left: 12px;
        font-weight: 500;
        font-size: 13px;
        margin-top: -20px;
    }

    .dogcard-cta-text {
        font-weight: 500;
        color: var(--green-shadow);
        font-size: 13px;
        margin-right: 5px;
    }

    .dogcard-cta {
        border-top-style: solid;
        border-top-width: 2px;
        border-top-color: var(--grey-border);
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 4px;
        display: flex;
    }

}
