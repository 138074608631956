.circle-loader {
    margin-bottom: calc(var(--loader-size) / 2);
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-color: var(--check-color);
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: var(--loader-size);
    height: var(--loader-size);
}

.load-complete {
    animation: none;
    border-color: var(--check-color);
    transition: border 500ms ease-out;
}

.checkmark-none {
    display: none;
}

.checkmark-complete {
    display: block;
}

.page-layout {
    position: absolute;
    top: 50%;
}

.checkmark-complete.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
}

.checkmark-complete:after {
    opacity: 1;
    height: var(--check-height);
    width: var(--check-width);
    transform-origin: left top;
    border-right: var(--check-thickness) solid var(--check-color);
    border-top: var(--check-thickness) solid var(--check-color);
    content: '';
    left: var(--check-left);
    top: var(--check-height);
    position: absolute;
}

@keyframes loader-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }

    20% {
        height: 0;
        width: var(--check-width);
        opacity: 1;
    }

    40% {
        height: var(--check-height);
        width: var(--check-width);
        opacity: 1;
    }

    100% {
        height: var(--check-height);
        width: var(--check-width);
        opacity: 1;
    }
}