.article-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.article-root {
    padding: 2rem 4rem;
    width: calc(100% - 8rem);
    font-family: var(--main-font);
    display: flex;
    flex-direction: column;
    max-width: 50rem;
}

.article-root h3 {
    font-size: 26px;
}

.article-root .subheader {
    font-weight: 500;
    color: var(--title2-color);
    font-size: 20px;
}

.article-root .header-title {
    font-size: 45px;
    margin-bottom: -5px;
}

.article-root p {
    font-size: 18px;
    font-weight: 400;
}

.article-root a {
    text-transform: none;
    text-decoration: none;
    color: inherit;
}

.feature-image-caption {
    color: var(--title2-color);
    width: 100%;
    text-align: center;
}

.article-img-caption {
    color: var(--title2-color);
    width: 100%;
    text-align: center;
}

.article-root .kg-btn {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    border-color: var(--primary-color);
    border-style: solid;
    border-width: 2px 2px 4px 2px;
    border-radius: 30px;
    color: var(--primary-color);
    font-weight: 500;
    justify-self: center;
    align-self: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.article-root .kg.image-card .kg-card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.article-root img {
    max-width: 100%;
    margin: 0px !important;
    padding: 0px !important;
    object-fit: contain;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
}

@media(max-width: 1200px) {

    .article-root {
        max-width: 100%;
    }

    .article-root img {
        max-width: 800px;
    }

    .article-root .header-title {
        line-height: 45px;
    }
}

@media(max-width: 890px) {

    .article-root img {
        max-width: 600px;
    }
}

@media(max-width: 730px) {

    .article-root {
        padding: 2rem 1rem;
        width: calc(100% - 2rem);
    }

    .article-root .header-title {
        font-size: 33px;
        line-height: 33px;
    }

    .article-root .subheader {
        font-size: 20px;
    }

    .article-root p {
        font-size: 18px;
    }

    .article-root img {
        max-width: 300px;
        max-height: 400px;
    }
}
