.fb-loading-root {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fb-button-root {
    border-style: solid;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 3px;
    padding: 6px 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    width: 230px;
    border-radius: 20px;
    font-size: 16px;
    color: #1877f2;
    font-weight: 500;
    border-color: #1877f2;
    cursor: pointer;
}

.fb-logo {
    width: 22px;
}