/* Common styles for all screen sizes */
.facebook-app-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 70vh;
    font-family: var(--main-font);
    gap: 20px;
  }
  
  .facebook-app-title {
    font-size: 50px;
    font-weight: 500;
  }
  
  .facebook-app-description {
    font-size: 24px;
    font-weight: 500;
    color: var(--title2-color);
    /* Limit the width and center the description text */
    max-width: 50%;
    margin: 0 auto;
  }

  .fb-app-account-text {
    color: var(--primary-color);
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
  
  /* Responsive styles using media queries */
  @media screen and (max-width: 768px) {

  
    .facebook-app-title {
      /* Adjust the font size for smaller screens */
      font-size: 40px;
    }
  
    .facebook-app-description {
      /* Adjust the font size for smaller screens */
      font-size: 20px;
      max-width: 70%;
    }
  }
  
  @media screen and (max-width: 576px) {

    .facebook-app-content {
      padding: 0 1rem;
    }
  
    .facebook-app-title {
      /* Further adjustments for even smaller screens */
      font-size: 32px;
    }
  
    .facebook-app-description {
      /* Further adjustments for even smaller screens */
      font-size: 18px;
      max-width: 90%;
    }
  }
  