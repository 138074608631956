.payment-tile {
    width: 127px;
    height: 83px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    border: 2px solid #e0e0e0;
    font-size: 13px;
    font-weight: 600;
    color: #727f96;
    border-radius: 10px;
    padding: 13px 10px 10px 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.payment-tile-selected {
    width: 127px;
    height: 83px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    border: 2px solid var(--green-shadow);
    font-size: 13px;
    font-weight: 600;
    color: var(--green-shadow);
    border-radius: 10px;
    padding: 13px 10px 10px 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.payment-name {
    margin-top: 5px;
}

