.checkout {
    display: flex;
    flex-direction: row;
    height: 100%;
    font-family: var(--main-font);
}

.checkout-success-left {
    width: 50%;
    height: 100vh;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    overflow: visible;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 10rem;
    box-sizing: border-box;
}

.checkout-success-summary-entry {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 3rem;
}

.checkout-success-summary-label {
    font-size: 13px;
    font-weight: 500;
    color: var(--title2-color);
}

.checkout-success-summary-value {
    font-size: 16px;
    font-weight: 500;
    color: var(--title-color);
}

.delivery-information {
    display: flex;
    gap: 3rem;
}

.map-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.checkout-success-title {
    font-size: 50px;

}

.checkout-success-description {
    font-size: 24px;
    margin-top: 20px;
    color: var(--title2-color);
}

.checkout-success-buttons {
    margin-top: 5rem;
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.checkout-card-details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
}

.checkout-right {
    width: 50%;
    font-weight: 500;
    color: white;
    height: 100vh;
    background: linear-gradient(90deg, rgba(149,210,88,1) 0%, rgba(93,200,0,1) 51%, rgba(149,210,88,1) 100%);
    display: flex;
    flex-direction: column;
    position: relative;
}

.checkout-payment-breakdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.checkout-price-entry {
    display: flex;
    justify-content: space-between;
    
}

.product-name {
    font-size: 13px;
    font-weight: 600;
    color: var(--grey-labels);
}

.product-price {
    font-size: 18px;
    font-weight: 600;
    color: var(--grey-labels);
}


.dogprofile-map-checkout {
    width: 100%;
    height: 100%;
  }

.checkout-right-header {
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 5vh;
}

.checkout-left-header {
    padding-left: 4rem;
    padding-right: 4rem;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.checkout-button {
    width: 100%;
    margin-top: 15px;
}

.checkout-right-title {
    font-size: 50px;
    line-height: 57.5px;
    
}

.checkout-right-description {
    font-size: 35px;
    line-height: 40.95px;
}

.checkout-left-title {
    font-size: 35px;
    line-height: 40.95px;
    color: var(--title-color);
}

.checkout-left-description {
    font-size: 16px;
    line-height: 21.6px;
    color: var(--title2-color);
}

.checkout-illustration-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    overflow: hidden;
    bottom: 0;
}

.checkout-form-container-bubbles {
    max-height: 70vh;
    overflow-y: auto;
}

.checkout-skip-container {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.checkout-skip-text {
    text-align: center;
    color: var(--green-message);
    cursor: pointer;
}
.checkout-skip-icon {
    margin-left: 7px;
    margin-top: 2px;
}

.checkout-lower-title {
    font-weight: 600;
    font-size: 16px;
}

.steps-container {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-bottom: 50px;
}

.checkout-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.checkout-bubbles {
    padding-top: 20px;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
  }

  
  
  .checkout-form-container-bubbles::-webkit-scrollbar {
    width: 2px;
  }
  
  .checkout-form-container-bubbles::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
  }
  
  .checkout-form-container-bubbles::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 4px;
  }
  
  .checkout-form-container-bubbles::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover-color);
  }

  @media (max-width: 992px) {

    .checkout-success-left {
        width: 100%;
    }

    .checkout-right {
        width: 0%;
    }
    .checkout-left {
        width: 100%;
    }
    .checkout-illustration {
        width: 0px;
    }

    .checkout-right-header {
        width: 0px;
        height: 0px;
        display: none;
    }

    .checkout-left-header {
        padding-left: 1rem;
        padding-right: 2rem;
    }

    .checkout-left-title {
        font-size: 23px;
        line-height: 40.95px;
        color: var(--title-color);
    }

    .checkout-left-description {
        font-size: 16px;
        line-height: 20px;
    }

    .checkout-bubbles {
        padding-top: 20px;
        width: 80vw;
        flex-wrap: wrap;
        display: flex;
        overflow-y: auto;
        max-height: 20vh;
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
      }
  }

  @media (max-width: 550px) { 
    .checkout-success-title {
        font-size: 26px;
    }

    .checkout-success-description {
        font-size: 18px;
    }

    .checkout-success-left {
        padding-left: 1rem;
        padding-right: 1rem;
    }
  }