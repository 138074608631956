.step-component {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  

  
  .step:hover {
    color: white;
    background-color: var(--green-shadow);
    border-color: var(--green-shadow);
    cursor: pointer;
  }


  .bubble {
    font-size: 16px;
    text-align: center;
    padding: 8px 24px 10px;
    border: 2px solid var(--grey-border);
    box-shadow: 0px 3px 0px var(--grey-border);
    border-radius: 100px;
    width: fit-content;
    margin-right: 10px;
    margin-bottom: 10px;
}

.bubble-filled {
    color: white;
    background-color: var(--green-shadow);
    cursor: pointer;
    border-color: var(--green-shadow);
    box-shadow: 0px 3px 0px var(--darker-green);
}

.bubble:hover {
    box-shadow: 0px 3px 0px var(--darker-green);
    cursor: pointer;
    border-color: var(--green-shadow);
}

@media (max-width: 500px) {
    .bubble {
        width: fit-content;
        font-size: 14px;
        padding: 4px 12px 5px;
    }
}