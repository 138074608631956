.badge {
    padding: 2px 10px;
    background-color: white;
    font-family: var(--main-font);
    border-radius: 20px;
    border-style: solid;
    border-width: 2px;
    border-color: #90DE6E;
    color: var(--green-shadow);
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    align-items: center;
}

.badge-text {
    margin-left: 5px;
}

@media (max-width: 768px) {
    .badge {
        font-size: 12px;
    }
}
