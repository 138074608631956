.not-found-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found-root {
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    gap: 4rem;
    width: calc(100% - 8rem);
    max-width: 100rem;
}

.not-found-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.not-found-h1 {
    font-family: var(--main-font);
    font-weight: 500;
    font-size: 50px;
    width: 50vw;
}


@media (max-width: 1048px) {

    .not-found-h1 {
        font-size: 35px;
    }
}

@media (max-width: 935px) {
    .not-found-root {
        flex-direction: column-reverse;
        padding: 2rem 2rem;
        width: calc(100% - 4rem);
    }

    .not-found-h1 {
        font-size: 35px;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 512px) {

    .not-found-h1 {
        font-size: 25px;
        width: 100%;
        text-align: center;
    }

    .not-found-img {
        width: 80%;
    }
}