.adoption {
    display: flex;
    flex-direction: row;
    height: 100%;
    font-family: var(--main-font);
}


.app-radio-options {
    display: flex;
}

.app-radio-option {
    margin-right: 10px;
    display: flex;
}

.adoption-summary-adopter-info {
    display: flex;
    padding-top: 20px;
    gap: 2rem;
}

.adoption-summary-entry {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.adoption-summary-entry-full-row {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 10px;
}

.adoption-summary-label {
    font-size: 13px;
    font-weight: 500;
    color: var(--grey-border);
}

.adoption-summary-value {
    font-size: 16px;
    font-weight: 500;
    color: var(--title2-color);
}

.adoption-summary-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.ribbon {
    position: absolute;
    top: 24px;
    right: 24px;
    background-color: var(--primary-color);
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .ribbon-text {
    color: white;
    font-weight: 500;
  }

.adoption-summary {
    margin-top: 15vh;
    width: 95%;
    height: 100%;
    border-width: 1px;
    border-color: var(--grey-border);
    border-style: solid;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    overflow-y: auto; /* Use 'auto' instead of 'scroll' to hide the scrollbar when not needed */
    padding-bottom: 300px;
}

/* Custom scrollbar styles for webkit-based browsers */
.adoption-summary::-webkit-scrollbar {
    display: none;
  }
  
  .adoption-summary::-webkit-scrollbar-thumb {
    background-color: var(--grey-border); /* Color of the scrollbar thumb */
    border-radius: 8px; /* Rounded corners for the scrollbar thumb */
  
    /* Add padding to the scrollbar thumb */
    margin: 6px;
  }
  
  .adoption-summary::-webkit-scrollbar-thumb:hover {
    background-color: var(--grey-border-hover); /* Color of the scrollbar thumb on hover */
  }
  
  .adoption-summary::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the scrollbar track */
  }

.adoption-summary-info {
    padding-top: 30px;
}

.adoption-summary-title {
    font-size: 24px;
    font-weight: 500;
} 

.adoption-img-summary {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    object-fit: cover;
}

.left {
    width: 64%;
    height: 100vh;
    font-weight: 500;
}

.adoption-contact-container {
    display: flex;
    padding-bottom: 2rem;
    align-items: center;
    gap: 12px;
}

label {
    display: flex;
    flex-direction: row;
}

input[type='radio'] {
    accent-color: var(--green-shadow);
}

adoption-checkbox:checked {
    border-color: var(--green-shadow);
}

.adoption-checkbox {
    /* Hide the default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid var(--green-shadow);
    /* Border color when unchecked */
    border-radius: 3px;
    outline: none;
    transition: border-color 0.3s;
}

.adoption-checkbox {
    /* Hide the default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    display: inline-block;
    min-width: 15px;
    max-width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 3px;
    outline: none;
    transition: background-color 0.3s;
}

/* Custom checkbox when checked */
.adoption-checkbox:checked {
    background-color: var(--green-shadow);
    /* Background color when checked */
}

/* Checkbox animation */
.adoption-checkbox:before {
    content: "\2714";
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11px;
    font-weight: bold;
    color: white;
    /* Tick color */
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
}

.adoption-checkbox:checked:before {
    opacity: 1;
}

.right-adoption {
    width: 36%;
    font-weight: 500;
    color: black;
    height: 100vh;
    background: #fafafa;
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: #f1f1f1;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}

.adoption-dog-name {
    font-size: 48px;
    color: black;
    font-weight: 500
}

.adoption-dog-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.adoption-more-info {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
}

.adoption-name {
    font-size: 24px;
}

.adoption-info-avatar {
    width: 212px;
    height: 212px;
    border-radius: 150px;
    object-fit: cover;
}

.checkbox-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    /* Adjust the value to set the desired gap between checkboxes */
}

.adoption-call-disclaimer {
    color: var(--title2-color);
    font-weight: 500;
    font-size: 13px;
}

.adoption-right-header {
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 5vh;
}

.adoption-left-header {
    padding-left: 4rem;
    padding-right: 4rem;
    /* max-width: 60rem;; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    height: 100vh;
    align-items: center;
}

.adoption-form-scrollable-container {
    overflow-y: auto;
    height: 70vh;
    scrollbar-width: thin;
    scrollbar-color: #999999 #f5f5f5;
    padding-right: 4vw;
    width: calc(100% - 8rem);
}

/* Track */
.adoption-form-scrollable-container::-webkit-scrollbar {
    width: 2px;
}

/* Handle */
.adoption-form-scrollable-container::-webkit-scrollbar-thumb {
    background-color: #999999;
    opacity: 0;
    transition: opacity 0.3s;
}

/* Handle on hover */
.adoption-form-scrollable-container::-webkit-scrollbar-thumb:hover {
    background-color: #666666;
    opacity: 1;
}

/* Track */
.adoption-form-scrollable-container::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

/* Hide scrollbar when not being interacted with */
.adoption-form-scrollable-container:hover::-webkit-scrollbar-thumb {
    opacity: 1;
}

.adoption-form-scrollable-container:hover::-webkit-scrollbar-thumb:hover {
    opacity: 1;
}



.adoption-mid-title {
    font-size: 24px;
}

.adoption-button {
    width: 100%;
    max-width: 350px;
}

.adoption-right-title {
    font-size: 50px;
    line-height: 57.5px;

}

.adoption-right-description {
    font-size: 35px;
    line-height: 40.95px;
}

.adoption-left-title {
    font-size: 35px;
    line-height: 40.95px;
    color: var(--title-color);
}

.adoption-left-description {
    font-size: 16px;
    line-height: 21.6px;
    color: var(--title2-color);
}

.adoption-illustration-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    overflow: hidden;
    bottom: 0;
}

.adoption-form-container-bubbles {
    max-height: 70vh;
    overflow-y: auto;
}

.adoption-skip-container {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.adoption-skip-text {
    text-align: center;
    color: var(--green-message);
    cursor: pointer;
}

.adoption-skip-icon {
    margin-left: 7px;
    margin-top: 2px;
}

.adoption-lower-title {
    font-weight: 600;
    font-size: 16px;
}

.steps-container {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-bottom: 50px;
}

.adoption-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adoption-button-wrapper {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 50px;
    width: calc(64% - 8rem);
}

.adoption-bubbles {
    padding-top: 20px;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}



.adoption-form-container-bubbles::-webkit-scrollbar {
    width: 2px;
}

.adoption-form-container-bubbles::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
}

.adoption-form-container-bubbles::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 4px;
}

.adoption-form-container-bubbles::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover-color);
}

@media (max-width: 1300px) {
    .right-adoption {
        flex-direction: column;
        justify-content: space-evenly;
    }
}

@media (max-width: 900px) {

    .right-adoption {
        width: 0%;
        display: none;
    }

    .left {
        width: 100%;
    }

    .adoption-illustration {
        width: 0px;
    }

    .adoption-right-header {
        width: 0px;
        height: 0px;
        display: none;
    }

    .adoption-form-container {}

    .adoption-left-header {
        margin-top: 10px;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .adoption-left-title {
        font-size: 23px;
        line-height: 40.95px;
        color: var(--title-color);
    }

    .adoption-left-description {
        font-size: 16px;
        line-height: 20px;
    }

    .adoption-bubbles {
        padding-top: 20px;
        width: 80vw;
        flex-wrap: wrap;
        display: flex;
        overflow-y: auto;
        max-height: 20vh;
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
    }


    .checkbox-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        /* Adjust the value to set the desired gap between checkboxes */
    }
}

@media (max-width: 800px) {
    .adoption-button-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: fixed;
        background-color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        bottom: 0;
        margin: 0;
        margin-left: -1rem;
        box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.1);
        height: fit-content;
        margin-top: 1rem; /* Add some margin to create space */
        left: 15px;
    }

    .adoption-form-scrollable-container {
        height: 60vh; /* Subtract the margin from the height */
        overflow-y: auto; /* Enable scrolling */
        overflow-x: hidden;
        width: calc(100% - 1rem);
        padding-right: 1rem;
    }

    .adoption-form-scrollable-container::-webkit-scrollbar {
        width: 1px;
      }
      
      .adoption-form-scrollable-container::-webkit-scrollbar-thumb {
        background-color: #000;
      }
      
      .adoption-form-scrollable-container::-webkit-scrollbar-track {
        background-color: #ccc;
      }

    .adoption-contact-container {
        flex-direction: column;
        align-items: normal;
        justify-content: left;
    }

    .adoption-left-header {
        top: 0;
    }

    .left {
        overflow: hidden;
        height: 100vh;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

@media (max-height: 768px) {
    .adoption-form-scrollable-container {
        height: 63vh;
    }
}


@media (max-height: 600px) {
    .adoption-form-scrollable-container {
        height: 50vh;
    }
}

@media (max-height: 400px) {
    .adoption-form-scrollable-container {
        height: 30vh;
    }
}