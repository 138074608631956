.option-button {
    box-sizing: border-box;
    border-radius: 100px;
    box-shadow: 0px 4px 0px var(--green-shadow);
    min-width: 166px;
    width: auto;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 13px 18px;
    justify-content: center;
    gap: 10px;
    font-family: var(--main-font);
    font-weight: 500;
    font-size: 21px;
    border: 2px solid var(--green-shadow);
    cursor: pointer;
    margin-right: 5px;
    white-space: nowrap;
    position: relative;
}

.options-balloon {
    position: absolute;
    top: calc(100% + 5px); /* Position the balloon below the button */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1; /* Ensure the balloon is above other content */
    background-color: var(--primary-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    margin-top: 5px; /* Add some space between button and balloon */
    display: none; /* Hide the balloon by default */
  }
  
  .option-button:hover .options-balloon {
    display: block; /* Show the balloon on option-button hover */
  }
  
  .options-balloon::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--primary-color);
  }
  
  .option-item {
    cursor: pointer;
    padding: 5px 10px;
  }

  .option-item:hover {
    background-color: var(--primary-color); /* Add the desired hover color */
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7); /* Add glowing shadow effect */
  }

.option-button-small {
    min-width: 0;
    height: fit-content;
    font-size: 16px;
    padding: 3px 18px;
    box-shadow: 0px 3px 0px var(--green-shadow);
}

.option-button:hover {
    background-color: var(--green-hover);
}

.option-button-normal:hover {
    background-color: var(--green-hover);
    color: white;
}

.option-button-filled {
    background-color: var(--primary-color);
    color: white;
}

.option-button-normal {
    background-color: white;
    color: var(--primary-color);
}

a {
    text-decoration: none;
}