.dog-profile-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 8rem);
  max-width: 99rem;
  padding-left: 4rem;
  padding-right: 4rem;
  margin: 0 auto;
}

.carousel-title-container {
  padding: 10px 1rem 20px 1rem;
  font-family: var(--main-font);
  font-weight: 500;
  font-size: 24px;
}



.carousel-container {
  position: relative;
  padding: 0 0.001rem;
  margin-bottom: 20px;
  width: 100%;
  max-width: 100vw;
}

.review-card-carousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  width: 100%;
  align-items: center;
}

.review-card-carousel::-webkit-scrollbar {
  width: 8px; /* Adjust the width as needed */
}

.review-card-carousel::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the scrollbar thumb color to transparent */
}

.review-card-2 {
  flex: 0 0 100%;
  scroll-snap-align: start;
}

.carousel-dots {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: var(--grey-border);
  cursor: pointer;
}

.carousel-dot.selected {
  background-color: var(--primary-color);
}



.dog-profile-root {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  font-family: var(--main-font);
  justify-content: center;
  overflow: hidden;
}

.dog-profile-back-container {
  padding-top: 2rem;
  display: flex;
  cursor: pointer;
  width: fit-content;
}

.dog-profile-photo-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dogprofile-map {
  width: 100%;
  height: 160px
}

.back-icon {
  margin-top: 1px;
}

.dog-profile-back {
  font-family: var(--main-font);
  font-weight: 500;
  font-size: 13px;
  margin-left: 10px;
}

.dog-profile-name {
  font-weight: 600;
  font-size: 40px;
}

.dog-profile-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dog-profile-header .options {
  font-weight: 500px;
  font-size: 16px;
  color: var(--title2-color);
  display: flex;
  flex-direction: row;
}

.options-wrapper {
  display: flex;
  cursor: pointer;
}

.options-wrapper-share {
  display: flex;
  margin-right: 25px;
  cursor: pointer;
}

.dog-profile-icon {
  margin-right: 5px;
}

.dog-profile-favorite {
  cursor: pointer;
}

.dog-profile-left {
  width: 550px;
  margin-right: 50px;
}

.dog-profile-right {
  width: 100%;
  max-width: 70rem;
}

.dog-profile-sep {
  height: 5px;
}

.dog-profile-description {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.dog-profile-heavy-text {
  font-weight: 500;
  font-size: 16px;
}

.dog-profile-description .dog-profile-heavy-text {
  margin-bottom: 10px;
}

.dog-profile-info-container {
  display: flex;
  margin-top: 30px;
}

.dog-profile-info-container2 {
  display: flex;
  margin-top: 30px;
}

.dog-info {
  display: flex;
  flex-direction: column;
  margin-right: 3vw;
}

.more-info-list {
  padding: 10px 0;
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.more-info-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.more-info-list-text {
  font-weight: 500;
  font-size: 14px;

}

.dog-info .title {
  font-size: 13px;
  color: var(--title2-color);
  font-weight: 500;
}

.dog-bold-info {
  font-weight: 500;
}

.location-info {
  margin: 5px 0;
  font-size: 16px;
}

.location-info-row {
  display: flex;
  gap: 5px;
  align-items: center;
}

.dog-info .data {
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;
}

.dog-profile-description-text {
  color: var(--title2-color);
  font-weight: 500;
  font-size: 16px;
  max-width: 100%;
}

.adoption-title {
  font-weight: 500;
  font-size: 24px;
}

.adoption-info-low {
  margin-top: 20px;
  background-color: var(--off-white);
  border-radius: 8px;
  width: 267px;
  height: 335px;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
}

.adoption-info-low .title {
  font-size: 13px;
  color: var(--title2-color);
  font-weight: 500;
}

.adoption-info-low .price {
  font-size: 35px;
  font-weight: 500;
}

.adoption-info-low .actual-price {
  font-size: 18px;
  font-weight: 500;
  margin-left: 5px;
  color: var(--title2-color);
  text-decoration: line-through;
}

.adoption-info-low .expiry {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  color: var(--primary-color);
}

.lower-section {
  display: flex;
}

.other-info-low {
  margin-left: 20px;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
}

.dog-profile-dogs {
  font-family: var(--main-font);
  /* padding-left: 4rem;
    padding-right: 4rem; */
}

.dog-profile-dogs .title {
  font-weight: 500;
  font-size: 35px;
}

.more-dogs-title-wrapper {
  margin-bottom: 50px;
}

.traits-tags {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}

.other-info-low .title {
  font-size: 13px;
  color: var(--title2-color);
  font-weight: 500;
  margin-bottom: 5px;
}

.other-info-low .title2 {
  font-size: 13px;
  color: var(--title2-color);
  font-weight: 500;
  margin-bottom: 5px;
}

.other-info-low .heavy-value {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}


.other-info-low .heavy-green-value {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  cursor: pointer;
  color: var(--primary-color);
}


.map-container {
  width: 356px;
  height: 117px;
}

.mobile-cta {
  display: none;
}

.mobile-cta-bottom {
  display: none;
}

/* Media Queries */
@media screen and (max-width: 1670px) {
  .dog-profile-wrapper {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

@media screen and (max-width: 1445px) {


  .dog-profile-wrapper {
    padding: 0;
    width: 100%;
  }

  .dog-profile-right {
    padding: 0 4rem;
  }

  .dog-profile-header .options {
    margin-top: 3px;
  }

  .dog-profile-dogs {
    padding: 0 4rem;
  }

  .dog-profile-back-container {
    margin-left: 4rem;
  }

  .dog-profile-photo-wrapper {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .dog-profile-left {
    width: 88%;
  }
}

@media screen and (max-width: 830px) {

  .dog-profile-back-container {
    display: none;
  }

  .dog-profile-wrapper {
    padding: 0px;
    position: relative;
  }

  .dog-profile-root {
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;
  }

  .dog-profile-photo-wrapper {
    padding: 0;
    padding-top: 0.5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .dog-profile-back-container {
    margin-left: 1rem;
  }

  .photo-container {
    width: 350px !important;
    height: 349px !important;
  }

  .dog-profile-right {
    padding: 1rem;
  }

  .dog-profile-left {
    width: 100%;
    margin-right: 0;
  }

  .dog-profile-description-text {
    max-width: 100%;
  }

  .dog-profile-header {
    padding-left: 1rem;
    padding-right: 1rem;
    width: calc(100% - 2rem);
  }

  .dog-profile-header .options {
    padding-top: 0.5rem;
    padding-left: 1rem;
  }

  .dog-profile-info-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    padding-left: 1rem;
    padding-right: 1rem;
    grid-gap: 10px;
    margin-top: 30px;
    width: calc(100% - 2rem);
  }

  .dog-profile-info-container2 {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    grid-gap: 10px;
    margin-top: 30px;
    flex-direction: column;
  }

  .traits-tags {
    padding: 0px 1rem;
  }

  .lower-section {
    display: flex;
    flex-direction: column;

  }

  .dog-profile-description {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .adoption-info-low {
    width: 100%;
    max-width: 320px;
    height: auto;
    /* margin-left: 0.5rem; */
  }

  .other-info-low {
    margin-left: 0.8rem;
    padding-right: 1rem;
  }

  .adoption-title {
    font-weight: 500;
    font-size: 24px;
    margin-left: 1rem;
  }

  .dogprofile-map {
    /* width: 350px; */
    height: 160px
  }

  .dog-profile-adoption {
    display: flex;
    justify-content: center;
  }

  .dog-profile-dogs {
    font-family: var(--main-font);
    /* display: none; */
    padding: 1rem;
  }

  .dog-buttons {
    position: fixed;
    bottom: 10px;
    ;
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 4rem;
    background-color: white;
    display: none;
  }

  .mobile-cta {
    font-family: var(--main-font);
    display: flex;
    bottom: 0;
    position: fixed;
    padding-bottom: 1rem;
    padding-top: 1rem;
    background-color: white;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    z-index: 1999;
  }

  .mobile-cta-bottom {
    font-family: var(--main-font);
    display: flex;
    bottom: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
    background-color: white;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    z-index: 1999;
    margin-bottom: -5rem;
  }

  .mobile-cta-left {
    display: flex;
  }

  .mobile-cta-left-none {
    display: none;
  }

  .mobile-cta-right-full {
    width: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }

  .mobile-cta-right {
    padding: 0 0.5rem;
  }

  .mobile-share {
    margin-left: 10px;
  }

  .mobile-heart {
    margin-left: 8px;
    margin-bottom: 3px;
  }

  .mobile-heart-filled {
    margin-left: 8px;
    fill: #53B62D;
  }

  .mobile-options-container {
    display: flex;
    align-items: center;

  }

  .dog-profile-name {
    font-size: 30px;
  }


  .mobile-cta-title {
    font-weight: 600;
    font-size: 30px;
    margin-left: 10px;
  }

  .dog-profile-dogs .title {
    font-weight: 500;
    font-size: 24px;
  }

  .other-info-low .heavy-green-value {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: var(--primary-color);
  }

}