.step-component {
    display: flex;
    font-family: var(--main-font);
    font-weight: 500;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}

.step {
    width: 34px;
    height: 22px;
    background-color: white;
    color: var(--green-shadow);
    border-style: solid;
    border-width: 2px;
    border-color: var(--darker-green);
    text-align: center;
    box-shadow: 0px 2px 0px var(--darker-green);
    border-radius: 100px;
    margin: 2px;
    cursor: pointer;
}

.step-label {
    white-space: nowrap !important;
    margin-top: 5px;
}

.filled {
    background-color: var(--green-shadow);
    color: white;
}

.step-separator {
    width: 100%;
    height: 2px;
    margin-bottom: 20px;
    background-color: var(--green-shadow);
    border-radius: 100px;
    align-self: center;
}

@media (max-width: 992px) {
    .step-component {
    }
}