.review-badge {
    border-width: 1px 1px 4px 1px;
    border-color: var(--grey-border);
    border-style: solid;
    border-radius: 20px;
    display: flex;
    gap: 10px;
    padding: 15px 10px;
    font-family: var(--main-font);
    background-color: #FAFAFA;
    height: fit-content;
  margin: 0 0rem;
  cursor: pointer;
  align-items: flex-start;
}

.review-badge-app {
    border-width: 1px 1px 4px 1px;
    border-color: var(--grey-border);
    border-style: solid;
    border-radius: 20px;
    display: flex;
    gap: 10px;
    padding: 20px 15px;
    font-family: var(--main-font);
    background-color: #FAFAFA;
    height: fit-content;
  /* margin-right: 1rem; */
  cursor: pointer;
  align-items: flex-start;
}

.review-badge-description-container {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.review-badge-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.review-badge-title {
    font-size: 10px;
    font-weight: 600;
    margin-top: -7px;
    color: var(--title2-color);
    text-align: center;
    line-height: 13.5px;
}

.review-badge-title2 {
    font-size: 10px;
    font-weight: 500;
    color: var(--title2-color);
    text-align: center;
    line-height: 13.5px;
}

.review-badge-title-container {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
}

.review-badge-description {
    font-size: 13px;
    color: var(--title2-color);
    font-weight: 500;
    /* margin-top: 4px; */
}

.review-badge-stars {
    display: flex;
    gap: 5px;
}

.review-badge-contents {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
