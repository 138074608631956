.profile-root {
    max-width: 99rem;
    margin: 0 auto;
    padding-top: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    font-family: var(--main-font);
    overflow: hidden;
}

.profile-back-link {
    cursor: pointer;
    width: fit-content;
    font-weight: 500;
    align-items: center;
    display: flex;
}

.profile-back-link .text {
    margin-left: 8px;
    font-size: 13px;
    margin-top: 1px;
}

.profile-header {
    margin-top: 3vh;
}

.profile-header .title {
    font-weight: 500;
    font-size: 35px;
}

.profile-nav {
    margin-top: 3vh;
    border-bottom: 1px solid var(--grey-border);
}

.profile-nav .options {
    display: flex;
}

.profile-nav .options .option {
    padding-right: 20px;
    padding-left: 20px;
    color: var(--title2-color);
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 8px;
    cursor: pointer;
}

.profile-nav .options .first-option {
    /* padding-left: 30px; */
}

.profile-nav .options .selected {
    color: var(--green-shadow);
    border-bottom: 3px solid var(--green-shadow);
}

.profile-tab1 {
    padding-top: 30px;
}

.profile-tab1 .header {
    display: flex;
    width: 100%;
    justify-content: space-between
}

.profile-tab1 .header .leftp {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
} 

.profile-tab1 .header .rightp {
    display: flex;
    flex-direction: column;
} 

.profile-enabled {
    justify-content: space-between;
}


.profile-inner-title {
    font-weight: 500;
    font-size: 24px;
}

.profile-inner-title2 {
    font-weight: 500;
    font-size: 16px;
    color: var(--title2-color);
    margin-top: 5px;
}

.profile-content {
    padding-top: 5px;
}

.profile-content2 {
    padding-top: 30px;
}

.profile-content .form-double {
    padding-top: 30px;
    display: flex;
    width: 48%;
    justify-content: space-between;
    padding-bottom: 5px;
}

.profile-content2 .form-double {
    padding-top: 30px;
    display: flex;
    width: 48%;
    justify-content: space-between;
    padding-bottom: 5px;
}

.profile-content2 .form-single {
    padding-top: 30px;
    display: flex;
    width: 48%;
    justify-content: space-between;
    padding-bottom: 5px;
}

.profile-delete {
    font-weight: 400;
    color: var(--red-error);
}

.profile-password {
    font-weight: 400;
    color: var(--green-shadow);
    cursor: pointer;
}

.profile-notification {
    display: flex;
    flex-direction: column;
}

.ant-switch-checked {
    background-color: var(--green-shadow) !important;
}

.ant-switch-primary {
    background-color: #53B62D !important;
}


.profile-tab1 .notification-panel {
    display: flex;
    padding-bottom: 40px;
}

.profile-tab1 .notification-panel .leftp {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
} 

.profile-tab1 .notification-panel .rightp {
    padding-top: 20px;
} 

.edit-profile {
    display: flex;
    justify-self: flex-end;
}


.profile-tab2-dogs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust the minmax values as needed */
    grid-auto-rows: minmax(100px, auto); /* Adjust the minmax value for row height as needed */
    grid-auto-flow: dense; /* Automatically create new rows when there's no more space */
    grid-gap: 20px; /* Add some gap between grid items */
    padding-top: 50px;
}

.profile-tab3-dogs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust the minmax values as needed */
    grid-auto-rows: minmax(100px, auto); /* Adjust the minmax value for row height as needed */
    grid-auto-flow: dense; /* Automatically create new rows when there's no more space */
    grid-gap: 20px; /* Add some gap between grid items */
    padding-top: 30px;
}

.profile-tab2-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
}

.profile-tab3 {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

.profile-tab3-title {
    font-size: 24px;
    font-weight: 500;
}

.profile-tab3-title2 {
    font-size: 16px;
    font-weight: 500;
    color: var(--title2-color);
    margin-top: 15px;
}


@media (max-width: 1480px) { 
    .profile-root {
        max-width: 77rem;
    }
}

@media (max-width: 500px) {
    .profile-root {
        display: flex;
        flex-direction: column;
        padding: 0.2rem;
        width: 92%;
        justify-content: left;
        padding-bottom: 5rem;
    }

    .profile-content .form-double { 
        flex-direction: column;
        height: 200px;
    }

     .profile-content2 .form-double { 
        flex-direction: column;
        height: 200px;
    }

    .edit-profile {
        flex-direction: column;
    }

    .profile-tab2-dogs {
        padding-left: 1.8rem;
    }

    .profile-tab3-dogs {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)); /* Adjust the minmax values as needed */
        grid-auto-rows: minmax(100px, auto); /* Adjust the minmax value for row height as needed */
        grid-auto-flow: dense; /* Automatically create new rows when there's no more space */
        grid-gap: 20px; /* Add some gap between grid items */
        padding-top: 30px;
    }

    /* .profile-root {
        width: 100%;
        max-width: 99rem;
        margin: 0 auto;
        padding-top: 2rem;
        padding-left: 4rem;
        padding-right: 4rem;
        font-family: var(--main-font);
        overflow: hidden;
    } */

    .profile-tab1 .notification-panel .leftp {
        display: flex;
        flex-direction: column;
        margin-right: 50px;
    } 
}
