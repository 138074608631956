.input {
    height: 50px ;
    border-style: solid;
    border-width: 2px;
    border-color: var(--grey-border);
    border-radius: 8px;
    box-shadow: 0 2px 0 0 var(--grey-border);
    font-family: var(--main-font);
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
}

.input-area {
    height: 100px !important;
    border-style: solid;
    border-width: 2px;
    border-color: var(--grey-border);
    border-radius: 8px;
    box-shadow: 0 2px 0 0 var(--grey-border);
    font-family: var(--main-font);
    padding-left: 10px;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 500;
    resize: none;
    /* width: 100%; */
}

.autocomplete-list {
    list-style: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.autocomplete-list-item {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  /* Styling the scrollbar */
  .input-suggestions::-webkit-scrollbar {
    width: 3px; /* Width of the vertical scrollbar */
  }
  
  .input-suggestions::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the scrollbar track */
  }
  
  .input-suggestions::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners for the thumb */
  }
  
  .input-suggestions::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scrollbar thumb on hover */
  }


.autocomplete-selected {
    background-color: #f6f6f6;
}

.input-suggestions {
    position: absolute;
    width: calc(100% + 15px);
    background-color: #fff;
    border-color: var(--grey-border);
    border-style: solid;
    border-width: 2px;
    max-height: 150px;
    top: 45px;
    border-radius: 10px;
    padding-left: 0;
    z-index: 2;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.credit-card-logo {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    height: 20px; /* Adjust the height as needed */
    width: auto; /* Adjust the width as needed */
  }

.input-wrapper {
    position: relative;
    width: 100%;
  }

.input:focus {
    outline: none;
}

.input-label {
    font-weight: 500;
    font-size: 16px;
    color: var(--title-color);
    margin-bottom: 8px;
    margin-left: 3px;
    white-space: nowrap;
    text-wrap: nowrap;
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.input-required {
    color: var(--additional-red);
}

.input-error {
    border-color: var(--red-error);
    box-shadow: 0 2px 0 0 var(--red-error);
}

.input-profile-settings {
    width: 385px;
    margin-right: 10px;
}

.input-disabled {
    background-color: var(--input-disabled);
}

.input-uneditable {
    background-color: #f7f7f7;
    box-shadow: 0 2px 0 0 #f1f1f1;
    border-color: #f1f1f1;
    color: #989898;
}

.label-uneditable {
    color: #989898;
}

@media (max-width: 768px) { 
    .input-profile-settings {
        width: 345px;
    }

    .input {
        height: 40px;
    }
}
