.photo-container {
    width: 550px;
    height: 504px;
    display: inline-block;
    border: 1px solid var(--grey-border);
    border-radius: 16px;
    position: relative;
    cursor: pointer;
}

@media screen and (max-width: 1445px) {
    .photo-container {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .photo-container {
        width: 100%;
        border: 0px solid var(--grey-border);
    }
}

.photo-container img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 16px;
}

.photo-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 16px;
  }

.photo-forward {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 45%;
}

.photo-back {
    cursor: pointer;
    position: absolute;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    left: 8px;
    top: 45%;
}

.modal-content {
    display: flex;
    align-items: center;
}

.modal-photo {
    height: 800px;
    width: 800px;
    object-fit: contain;
}

.modal-photo img {
    height: 800px;
    width: 800px;
    object-fit: contain;
}

.modal-thumbnails {
    display: grid;
    grid-template-columns: repeat(2, minmax(64px, 1fr));
    grid-gap: 8px;
    margin-top: 16px;
    margin-left: 1vw;
}

.modal-thumbnails img {
    cursor: pointer;
    height: 106px;
    width: 117px;
    object-fit: cover;
    border-radius: 8px;
}

.modal-thumbnails .video-thumbnail {
    cursor: pointer;
    height: 106px;
    width: 117px;
    object-fit: cover;
    border-radius: 8px;
}

.modal-thumbnails img.active {
    border: 2px solid var(--primary-color);
}

.modal-thumbnails .active {
    border: 2px solid var(--primary-color);
}
