.agreement-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.cloud-icon {
    fill: var(--primary-color);
}

.agreement {
    width: calc(100% - 8rem);
    max-width: 100rem;
    justify-content: space-between;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 3rem;
    font-family: var(--main-font);
    display: flex;
}

.agreement-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.agreement-left {
    width: 50%;
}


.agreement-right {
    width: 40%;
    display: flex;
    justify-content: right;
    flex-direction: column;
    gap: 20px;
}

.agreement-title {
    font-size: 35px;
    font-weight: 500;

}

.agreement-list {
    padding-left: 1.5rem;
    line-height: 30px;
    font-size: 16px;
    font-weight: 500px;
    color: var(--title2-color);
}

.agreement-disclaimer {
    line-height: 30px;
    font-size: 16px;
    font-weight: 500px;
    color: var(--title2-color);
}

.agreement-upload-box {
    border: dashed 1px #dcdcdc;
    width: 98%;
    height: 290px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.file-over {
    background-color: rgba(83, 182, 45, 0.1);
}

.agreement-signature-box {
    border: dashed 1px #dcdcdc;
    width: 98%;
    height: 120px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.agreement-signature-input {
    width: 96%;
}

.agreement-confirmation {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
}

.agreement-box-placeholder {
    font-size: 13px;
    font-weight: 500;
    color: var(--title2-color);
}

.agreement-box-title {
    box-sizing: border-box;
    background-color: rgba(83, 182, 45, 0.1);
    padding: 5px 12px 5px 12px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    color: var(--primary-color);
}

.agreement-box-signature {
    font-family: 'Great Vibes', cursive;
    font-size: 32px;
    font-weight: 400;
}

.agreement-preview-image {
    width: 300px;
    height: 200px ;
    object-fit: cover;
    border-radius: 10px;
}

.agreement-pdf-image {
    width: 150px;
    height: 100px ;
    object-fit: contain;
    border-radius: 10px;
}

@media screen and (max-width: 900px) { 
    .agreement {
        flex-direction: column;
    }

    .agreement-right {
        padding-top: 20px;
        width: 100%;
    }

    .agreement-left {
        width: 100%;
    }

    .agreement-preview-image {
        width: 150px;
        height: 100px ;
        object-fit: cover;
        border-radius: 10px;
    }
}

