.footer {
    padding: 5.1rem 0 0 0;
    font-family: var(--main-font);
}

.footer-upper {
    display: flex;
    text-align: center;
}


.footer-description {
    font-weight: 500;
    font-size: 24px;
    color: var(--title2-color);
    line-height: 36px;
}

.footer-title {
    font-weight: 500;
    font-size: 50px;
    line-height: 57.5px;
    color: var(--title-color);
}

.footer-cta-buttons {
    display: flex;
    justify-content: center;
}


.footer-cta-buttons {
    padding-bottom: -20px;
}

.footer-illustration {
    object-fit: cover;
    padding-top: 120px;
    margin-bottom: -70px;
}

.footer-bottom {
    background-color: var(--green-shadow);
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 60px;
}

.footer-menu {
    margin: auto;
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;
}

.footer-menu ul {
    display: flex;
    padding: 0;
}

.footer-menu li {
    list-style-type: none;
    margin: 0 1rem;
}

.footer-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    color: white;
}

.temp-logo {
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 24px;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.footer-copyright-disclaimer {
    background-color: var(--green-shadow);
    margin-top: -10px;
    text-align: center;
    padding-bottom: 15px !important;
}

.footer-copyright-text {
    color: white;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 100px !important;
}

.footer-logo {
    cursor: pointer;
}

.illustration2 {
    width: 380px;
}

@media (max-width: 768px) {

    .footer {
        padding: 0;
        margin-top: 5rem;
        font-family: var(--main-font);
    }

    .footer-menu {
        margin: auto;
        color: white;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 30px;
    }

    .footer-menu li {
        list-style-type: none;
        margin: 0 0.3rem;
    }

    .footer-copyright-text {
        font-size: 12px;
    }
}

