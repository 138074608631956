.navbar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navigation {
  height: 56px;
  width: 100%;
  max-width: 100rem;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 1rem;
  background-color: #fff;
  color: black;
  font-family: var(--main-font);
  font-weight: 500;
  padding-left: 4rem;
  padding-right: 4rem;
}

.brand {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  cursor: pointer;
  margin-right: auto;
}

.navigation-menu {
  display: flex;
  justify-content: center;
  flex: 1;
  position: absolute;
  margin-top: 10px;
  left: 50%;
  right: 50%;
}

.navbar-account-icon {
  margin-top: 4px;
}

.navigation-buttons {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  gap: 5px;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: #000;
}

.navigation-menu li a:visited {
  color: #000;
}

.navigation-menu li a:focus {
  color: #000;
}

.logo {
  width: 140px;
  margin-top: 10px;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
  margin-left: 1rem;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: black;
}

/* @media screen and (max-width: 1670px) {
  .navigation {
    padding-left: 0rem;
    padding-right: 0rem;
  }
} */




@media (max-width: 1100px) {
  .brand {
    padding-bottom: 3px;
  }

  .menu-toggle {
    margin-top: 5px;
  }

  .navigation-menu {
    display: none;
  }

  .navigation-buttons {
    display: flex;
    flex-direction: column;
    /* padding-top: 3rem; */
  }

  .navigation {
    padding-left: 4rem;
    padding-right: 4rem;
    /* border: 1px solid */
    /* margin-bottom: 40px; */
  }

  .menu-toggle {
    display: flex;
  }

  .navigation.open .navigation-menu {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    /* padding: 1rem; */
    z-index: 999;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
    justify-content: center;
    align-items: center;
  }


  .navigation.open .navigation-menu ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .navigation.open .navigation-menu li {
    /* margin: 0; */
    margin-bottom: 1rem;
  }

  .navigation.open .navigation-menu a:hover {
    color: var(--primary-color);
  }

  .available-dogs-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .navigation {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
