.adoption-card {
    font-family: var(--main-font);
    display: flex;
    text-align: left;
    box-sizing: border-box;
    min-width: 264px;
    height: 83px;
    border: 1px solid;
    border-color: var(--grey-border);
    border-radius: 16px;
    padding: 11px;
}

.adoption-card-text-container {
    display: flex;
    flex-direction: column;
}

.adoption-card-photo {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 10px;
}

.adoption-card-date {
    font-size: 16px;
    color: var(--title2-color);
    font-weight: 500;
}

.adoption-card-name {
    font-size: 35px;
    font-weight: 500;
    line-height: 40.95px;
}